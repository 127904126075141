import React, { useState, useEffect } from "react";
import classes from "./styles.module.scss";
import { useNavigate } from "react-router";

const BARS_TITLES = [
  "Analyzing Skin Condition",
  "Meals and Activity",
  "Your Style Preference",
  "Generating Your Action Plan",
];

const LoadingBars = () => {
  const navigate = useNavigate();

  const [loadingProgress, setLoadingProgress] = useState([0, 0, 0, 0]);

  useEffect(() => {
    const getRandomIncrement = () => Math.floor(Math.random() * 19) + 1;

    const simulateLoading = async () => {
      for (let i = 0; i < loadingProgress.length; i++) {
        const totalSteps = 10;
        for (let step = 1; step <= totalSteps; step++) {
          await new Promise((resolve) => {
            setTimeout(() => {
              setLoadingProgress((prevProgress) =>
                prevProgress.map((progress, index) =>
                  index === i
                    ? step === totalSteps
                      ? 100
                      : Math.min(progress + getRandomIncrement(), 100)
                    : progress
                )
              );
              resolve();
            }, (2000 * (i + 1)) / totalSteps);
          });
        }
      }
    };

    const makeNextStep = async () => {
      await simulateLoading();
      navigate("/get-plan");
    };

    makeNextStep();
  }, []);

  return (
    <div className={classes.loadingBarsContainer}>
      {loadingProgress.map((progress, index) => (
        <div
          key={index}
          className={
            progress === 0
              ? `${classes.loadingBar} ${classes.disabled}`
              : `${classes.loadingBar}`
          }
        >
          <div className={classes.title}>
            <h5>{BARS_TITLES[index]}</h5>
            <div className={classes.percentage}>
              {progress < 100 && progress !== 0 && (
                <div className={classes.loader}></div>
              )}
              <div className={classes.progressText}>{progress}%</div>
            </div>
          </div>
          <div className={classes.progressBarContainer}>
            <div
              style={{ width: `${progress}%` }}
              className={`${classes.progressBar} ${
                progress === 100 ? classes.loaded : ""
              }`}
            ></div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default LoadingBars;
