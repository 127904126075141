import { Button } from "../shared/components/button";
import { useNavigate } from "react-router";
import { useSelector } from "react-redux";
import classes from "./styles.module.scss";
import { Images } from "../../../utils/images";

export const InitialOne = () => {
  const steps = useSelector((state) => state.steps.steps);
  const { welcome } = Images;
  const navigate = useNavigate();
  const navigateToQestionsHandler = () => {
    navigate("/questions?q=" + steps[0]);
  };
  return (
    <div className={classes.initial}>
      <div className={classes.content}>
        <img src={welcome} alt="img" />
        <h3 className={classes.title}>
          What is <span>Face yoga?</span>
        </h3>
        <p className={classes.paragraph}>
          Face yoga is a holistic method to enhance your personal beauty from
          any location. It promotes a healthy, silky-smooth and wrinkle-free
          appearance for your face. Moreover, this technique provides an
          illuminating effect to your skin while avoiding premature aging.
        </p>
      </div>
      <div className={classes.control}>
        <Button onClick={navigateToQestionsHandler}>Ok, got it!</Button>
      </div>
    </div>
  );
};
