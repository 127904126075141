import { Button } from "../../../shared";
import classes from "./styles.module.scss";

export const WellDoneBreakdown = ({ onNextStep }) => {
  return (
    <div id="breakdown" className={classes.breakdown}>
      <div className={classes.background}>
        <div className={classes.text}>
          <h3>Well done!</h3>
          <p>
            You're close to getting your Personal Program. Now, a few more
            questions to adjust it.
          </p>
        </div>
      </div>
      <div className={classes.control}>
        <Button onClick={onNextStep}>Continue</Button>
      </div>
    </div>
  );
};
