import {
  ButtonWithAnswer,
  ButtonsGroup,
  QuestionsContainer,
} from "../../../shared";

const ANSWERS = [
  "I barely have any time for myself",
  "I'm quite busy, but I try to make time for myself every day",
  "I have a lot of free time",
];

export const BusyDuringTheDay = ({ onNextStep }) => {
  return (
    <QuestionsContainer title="How busy are you during the day?">
      <ButtonsGroup>
        {ANSWERS.map((answer) => (
          <ButtonWithAnswer key={answer} onClick={onNextStep}>
            {answer}
          </ButtonWithAnswer>
        ))}
      </ButtonsGroup>
    </QuestionsContainer>
  );
};
