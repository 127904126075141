// Libs
import { useNavigate } from "react-router";
import back from "../../assets/back.png";

import classes from "./styles.module.scss";

const SubscriptionPolicy = () => {
  const navigate = useNavigate();

  return (
    <div className={classes.page}>
      <div className={classes.headerWrapper}>
        <button className={classes.headerButton} onClick={() => navigate(-1)}>
          <img src={back} alt="" />
        </button>
        <h1>Subscription Policy</h1>
      </div>
      <br />
      <p>Last updated December 23, 2022 </p>
      <br />
      <p>
        THIS REFUND POLICY APPLIES ONLY IF YOUR ACCESS TO FaceYoga (THE
        “SERVICE”) HAS BEEN PURCHASED DIRECTLY ON THE WEBSITE AVAILABLE VIA THE
        LINK HTTPS://FACEYOGA.HEALTH (THE “WEBSITE”). ANY FEATURES OF THE
        SERVICE (SUBSCRIPTIONS, ADD-ON ITEMS) PURCHASED VIA AN APP STORE ARE
        SUBJECT TO SUCH APP STORE'S REFUND POLICIES. THUS, YOU HAVE TO CONTACT
        AN APP STORE’S SUPPORT TO REQUEST A REFUND.
      </p>
      <br />
      <p>
        By using the Service on any computer, mobile phone, tablet or other
        device (collectively the “Device”), you as a user of the Service confirm
        that you have read, understand and agree to be bound by this Refund
        Policy and any other applicable law.
      </p>
      <br />
      <p>
        We may change this Refund Policy at any time without notice, effective
        upon its posting on the Website. Your continued use of the Service shall
        be considered your acceptance of the revised Refund Policy. If you do
        not agree to this Refund Policy, please do not use this Service.
      </p>
      <br />
      <p>
        Through the Website you may purchase the access to the paid Content (as
        defined in section “Intellectual Property” of the Terms of Use) of the
        Service (“Purchase”) using any of the following payment methods:{" "}
      </p>
      <br />
      <ul>
        <li>
          {" "}
          Bank Card: you can make a Purchase simply and securely using your
          Mastercard, American Express or Visa. Your bank card information will
          be encrypted at the point of transaction.
        </li>
        <br />
        <li>
          {" "}
          PayPal: you can make a Purchase simply and securely via our Website,
          opting for the PayPal payment method during checkout.
        </li>
        <br />
        <li>
          Apple Pay: you can make a Purchase simply and securely via our
          Website, opting for the Apple Pay payment method during checkout
        </li>
      </ul>
      <br />
      <p>
        The cost of the Purchase (either one-time payment or subscription
        payment) is provided within the Service on the relevant checkout screen.
      </p>
      <br />
      <p>
        You will be granted access to the Content immediately after your
        transaction (either one-time payment or subscription payment) is
        successfully validated by our servers and upon setting up a password.
      </p>
      <br />
      <p>
        Please note that to process the payment we use third-party services
        (i.e. payment processors). Such services enable the transaction
        initiated by you and notify us of its completion. We do not store or
        collect your payment details ourselves. This information is provided
        directly to our third-party payment processors.
      </p>
      <br />
      <p>
        You agree that the Purchase is final, that we will not refund any
        transaction once it has been made and that the Purchase cannot be
        cancelled. Notwithstanding the foregoing, you may be eligible to receive
        a refund of one-time payment or last subscription payment in the
        following cases:
      </p>
      <br />
      <p>
        (A) You have experienced technical issues with your Purchase within 30
        (thirty) calendar days starting from the Purchase date and have
        requested a refund during the same 30 (days) from the
      </p>
      <br />
      <p>
        date of Purchase (where the Purchase date shall be understood as the
        date of validation of your payment by our servers for one-time payment
        or as the date of validation of your last subscription payment by our
        servers) and, as a result, your use of the Purchase is rendered
        impossible.
      </p>
      <br />
      <p>
        To understand whether you are eligible for a refund, we may ask you to
        provide the following information:
      </p>
      <br />
      <ul>
        <li>your device’s model, operating system and version;</li>
        <li>the steps you are taking when this issue appears;</li>
        <li>a screen video or screenshots that would illustrate the issue; </li>
        <li>
          any additional information that would help us identify the problem, if
          the details you
        </li>
      </ul>
      <br />
      <p>
        have already provided are not enough to identify the issue you're
        experiencing.
      </p>
      <br />
      <p>
        The technical issues may include material crashes, failure to launch,
        non-clickability of buttons and other issues, which render your use of
        the Purchase impossible.
      </p>
      <br />
      <p>
        (B) If you did not get visible results after following the Content
        recommendations and met all of the following conditions:
      </p>
      <br />
      <ul>
        <li>
          you contact us within 30 (thirty) calendar days as of the Purchase
          date (where the Purchase date shall be understood as the date of
          validation of your payment by our servers for one-time payment or as
          the date of validation of your last subscription payment by our
          servers); and
        </li>
        <br />
        <li>
          you have followed our personalized plan at least during 14 (fourteen)
          consecutive days; and
        </li>
        <br />
        <li>
          you are able to demonstrate that you have actually followed the plan
          by providing us with a screen video or screenshots of your plan
          history, which you have personally passed.
        </li>
      </ul>
      <br />
      <p>
        To be eligible for a refund on the grounds of not getting visible
        results, the minimum number of completed training sessions in your plan
        during 14 (fourteen) consecutive days (two weeks) should be equal to at
        least 14 training sessions, namely: at least 70 minutes per each week.
      </p>
      <br />
      <p>
        Should you buy one week subscription, to be eligible for a refund on the
        grounds of not getting visible results, the minimum number of completed
        training sessions in your plan during 7 (seven) consecutive days (one
        week) should be equal to at least: 7 training sessions, you must not
        skip training sessions.
      </p>
      <br />
      <p>
        All refunds will be calculated according to the actual Purchase price
        paid at the time of Purchase. Please note that returns for credit and
        debit card transactions are issued within 2 (two) business days, however
        it may take up to 5 (five) business days for the credit to arrive at
        your credit card or bank account.
      </p>
      <br />
      <p>
        If you are mistakenly sent a different product than what you ordered on
        the Website, we will replace the product at no additional cost to you.
      </p>
      <br />
      <p>
        We are not liable for any refund amount due to technical problems on
        your Device, including but not limited to: hardware malfunction and/or
        problems due to Internet connectivity.{" "}
      </p>
      <br />
      <p>
        If you want to cancel your Purchase and are entitled to any refund, we
        reserve the right to charge a fee to cover the cost of any
        administrative or other expenses we may have suffered due to your order,
        to the extent permitted by law.{" "}
      </p>
      <br />
      <p>
        To request a refund or exchange, please contact Website customer service
        at support@faceyoga.health.
      </p>
      <br />
      <p>
        If you live in the European Union you have certain rights to withdraw
        from distance purchases; however, please note that when you order the
        Purchase, you acknowledge and agree that you accept and consume the
        Purchase promptly once our servers validate your Purchase and the
        purchased Content is successfully delivered to you and therefore your
        right of withdrawal is lost at this point. BY STARTING DOWNLOADING THE
        PURCHASE YOU HEREBY EXPRESSLY CONSENT TO IMMEDIATE PERFORMANCE OF THE
        AGREEMENT AND ACKNOWLEDGE THAT YOU WILL LOSE YOUR RIGHT OF WITHDRAWAL
        FROM THE AGREEMENT ONCE OUR SERVERS VALIDATE YOUR PURCHASE AND THE
        APPLICABLE PURCHASE IS SUCCESSFULLY DELIVERED TO YOU.{" "}
      </p>
      <br />
      <p>
        If we incur costs and/or expenses because a payment is declined and this
        is your fault (e.g. because there are insufficient funds in the account
        or the credit card limit has already been exhausted), then we are
        entitled to bill you for the actual costs and/or expenses incurred.
      </p>
      <br />
      <p>
        Where there is a legitimate reason, we reserve the right for each
        Purchase to refrain from offering certain payment methods and to specify
        alternative payment methods.
      </p>
    </div>
  );
};

export default SubscriptionPolicy;
