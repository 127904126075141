import "./App.css";
import { StartPage } from "./routes/startPage/StartPage";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { Questions } from "./routes/questions";
import { BeforeAfter } from "./routes/beforeAfter/BeforeAfter";
import { AuthPage } from "./routes/auth";
import { ProfilePage } from "./routes/profile";
import PaywallPage from "./routes/paywall/PaywallPage";
import { ObserveProcessPage } from "./routes/observeProcessPage";
import { FinishTouches } from "./routes/finishTouches";
import { JoinCommunites } from "./routes/joinCommunites";
import ProtectedRoutes from "./api/ProtectedRoutes";
import DayExercises from "./routes/dayExercises";
import SomethingWentWrong from "./routes/somethingWentWrong/SomethingWentWrong";
import { Resolve } from "./routes/paymentResult/Resolve";
import { Reject } from "./routes/paymentResult/Reject";
import PrivacyPolicy from "./routes/privacyPolicy/PrivacyPolicy";
import SubscriptionPolicy from "./routes/subscriptionPolicy/SubscriptionPolicy";
import TermsOfUse from "./routes/termsOfUse/TermsOfUse";
import { QuestionsTest } from "./routes/configurableQuestions/questions";
import { QuestionsWrapper } from "./routes/configurableQuestions/questionsWrapper";
import { InitialStep } from "./routes/configurableQuestions/initial";
import { useEffect } from "react";
import { Images } from "./utils/images";
import { Analyze } from "./routes/analyze";
import { GetPlan } from "./routes/getPlan";
import { Plan } from "./routes/plan";
import { ThankYouPage } from "./routes/thankyou";
import { PenddingReview } from "./routes/pendingReview";

function App() {
  const removeLoader = () => {
    const loader = document.querySelector("#loader");
    if (loader) {
      loader.style.display = "none";
    }
  };
  useEffect(() => {
    if (document.readyState === "complete") {
      removeLoader();
    } else {
      window.addEventListener("load", removeLoader);
    }
  }, []);
  useEffect(() => {
    Object.values(Images).forEach((image) => {
      const img = new Image();
      img.src = image;
    });
  }, []);

  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route element={<QuestionsWrapper />}>
            <Route element={<QuestionsTest />} path="/questions" />
            <Route element={<InitialStep />} path="/" />
            <Route element={<Analyze />} path="/analyze" />
            <Route element={<GetPlan />} path="/get-plan" />
            <Route element={<Plan />} path="/plan" />
          </Route>
          <Route element={<PrivacyPolicy />} path="/privacy-policy" />
          <Route element={<SubscriptionPolicy />} path="/subscription-policy" />
          <Route element={<TermsOfUse />} path="/terms-of-use" />
          <Route element={<JoinCommunites />} path="/join-communites" />
          <Route element={<FinishTouches />} path="/finish-touches" />
          <Route element={<PaywallPage />} path="/paywall" />
          <Route element={<ObserveProcessPage />} path="/observe-process" />
          <Route element={<AuthPage />} path="/auth" />
          <Route element={<Resolve />} path="/resolve" />
          <Route element={<Reject />} path="/reject" />
          <Route element={<BeforeAfter />} path="before-after" />
          <Route element={<ThankYouPage />} path="/thankyou" />
          <Route element={<PenddingReview />} path="/pending-review" />

          <Route
            element={<SomethingWentWrong />}
            path="/something-went-wrong"
          />
          <Route element={<ProtectedRoutes />}>
            <Route element={<ProfilePage />} path="/profile" />
            <Route
              element={<SomethingWentWrong />}
              path="/something-went-wrong"
            />
            <Route element={<DayExercises />} path="day-exercises/:id" />
          </Route>
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
