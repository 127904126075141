import { useDispatch } from "react-redux";
import { Suspense, useEffect } from "react";
import { getSequenceThunk } from "../../redux/slices/thunks/stepsThunk";
import { Outlet } from "react-router";
import classes from "./styles.module.scss";
import { Loader } from "../../common/loader";

export const QuestionsWrapper = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getSequenceThunk());
  }, []);

  return (
    <main className={classes.layout}>
      <Suspense fallback={<Loader />}>
        <Outlet />
      </Suspense>
    </main>
  );
};
