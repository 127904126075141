export const PendingIcon = () => {
  return (
    <svg
      width="78"
      height="78"
      viewBox="0 0 78 78"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="39" cy="39" r="39" fill="url(#paint0_linear_1869_2656)" />
      <path
        d="M39 14V38.5858C39 38.851 39.1054 39.1054 39.2929 39.2929L50.5 50.5"
        stroke="white"
        stroke-width="8"
        stroke-linecap="round"
      />
      <defs>
        <linearGradient
          id="paint0_linear_1869_2656"
          x1="-3.77744e-06"
          y1="8.71718e-07"
          x2="78"
          y2="78"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#FF96AD" />
          <stop offset="1" stop-color="#FF5A7F" />
        </linearGradient>
      </defs>
    </svg>
  );
};
