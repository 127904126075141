import {
  ButtonWithAnswerEmoji,
  ButtonsGroup,
  QuestionsContainer,
} from "../../../shared";

const ANSWERS = [
  {
    emoji: "😴",
    title: "Completely passive",
  },
  {
    emoji: "🚶‍♀️",
    title: "Not really active",
  },
  {
    emoji: "🏃",
    title: "Somewhat active",
  },
  {
    emoji: "🔥",
    title: "Very active",
  },
];

export const Lifestyle = ({ onNextStep }) => {
  return (
    <QuestionsContainer title="Do you usually include vegetables in your meals?">
      <ButtonsGroup>
        {ANSWERS.map(({ emoji, title }) => (
          <ButtonWithAnswerEmoji emoji={emoji} onClick={onNextStep}>
            {title}
          </ButtonWithAnswerEmoji>
        ))}
      </ButtonsGroup>
    </QuestionsContainer>
  );
};
