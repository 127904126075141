import {
  ButtonWithAnswer,
  ButtonsGroup,
  QuestionsContainer,
} from "../../../shared";

const ANSWERS = ["Yes", "No"];

export const AfraidToLoseBeauty = ({ onNextStep }) => {
  return (
    <QuestionsContainer title='"I&apos;m afraid to lose my beauty with aging"'>
      <ButtonsGroup>
        {ANSWERS.map((answer) => (
          <ButtonWithAnswer key={answer} onClick={onNextStep}>
            {answer}
          </ButtonWithAnswer>
        ))}
      </ButtonsGroup>
    </QuestionsContainer>
  );
};
