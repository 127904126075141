import { Images } from "../../../../../utils/images";
import {
  Button,
  ButtonsGroup,
  CardWithCheckbox,
  QuestionsContainer,
} from "../../../shared";
import { useChecks } from "../../../shared/hooks";
import classes from "./styles.module.scss";

const AREAS_OPTIONS = ["Forehead", "Eye area", "Chin", "Jawline", "Neck"];

export const AreasToFocus = ({ onNextStep }) => {
  const { isChecked, checkHandler, checkedCards } = useChecks();
  const { areas } = Images;
  return (
    <QuestionsContainer
      title="Which area would like to focus on?"
      subtitle="Choose all that apply"
      img={areas}
    >
      <div className={classes.group}>
        <ButtonsGroup>
          {AREAS_OPTIONS.map((option) => (
            <CardWithCheckbox
              onClick={checkHandler(option)}
              key={option}
              title={option}
              checked={isChecked(option)}
            />
          ))}
        </ButtonsGroup>
      </div>
      <div className={classes.control}>
        <Button disabled={!checkedCards.length} onClick={onNextStep}>
          Continue
        </Button>
      </div>
    </QuestionsContainer>
  );
};
