import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { openPayPal } from "../../../redux/slices/subscriptionSlice";
import { useNavigate } from "react-router";
import { axiosInstance } from "../../../api";

export const useFetchPlans = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [plans, setPlans] = useState([]);
  const [open, setOpen] = useState(false);
  const [url, setUrl] = useState("");
  const [activePlan, setActivePlan] = useState("");
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    const fetchPlans = async () => {
      try {
        const res = await axiosInstance.get("subscription-plans");
        const { items } = res.data;
        setPlans(items);
        const initialActivePlanIndex = items.findIndex(
          (plan) => plan.uiDisplayOptions.title
        );
        const initialActivePlan =
          initialActivePlanIndex !== -1
            ? items[initialActivePlanIndex]
            : items[0];
        setActivePlan(initialActivePlan.id);
      } catch (error) {
        console.log(error);
      }
    };

    fetchPlans();
  }, []);

  const handleSubmitPlan = async () => {
    const res = await dispatch(openPayPal(activePlan));
    setOpen(true);
    setUrl(res.payload.data.paymentPageUri + "&exfo=742");
  };

  useEffect(() => {
    const navigateAfterPurchase = (e) => {
      if (e.data.event === "thankyou-page-reached") {
        if (e.data.status === "purchase_successful") {
          navigate("/thankyou");
        }
        if (e.data.status === "purchase_review") {
          navigate("/pending-review");
        }
      }
    };
    window.addEventListener("message", navigateAfterPurchase);

    return () => window.removeEventListener("message", navigateAfterPurchase);
  }, []);

  const handleLoad = () => {
    setLoading(false);
  };

  const onDismiss = () => {
    setOpen(false);
    setLoading(true);
  };

  return {
    plans,
    activePlan,
    setActivePlan,
    handleSubmitPlan,
    open,
    setOpen,
    url,
    loading,
    handleLoad,
    onDismiss,
  };
};
