export const QuestionCheckbox = ({ checked }) => {
  return checked ? (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18 10C18 14.4183 14.4183 18 10 18C5.58172 18 2 14.4183 2 10C2 5.58172 5.58172 2 10 2C14.4183 2 18 5.58172 18 10Z"
        fill="#FF5A7F"
      />
      <path
        d="M7.17578 10.0008L9.99931 12.8243L17.5287 5.29492"
        stroke="white"
        stroke-width="2.82353"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  ) : (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        opacity="0.1"
        cx="10"
        cy="10"
        r="7"
        stroke="black"
        stroke-width="2"
      />
    </svg>
  );
};
