// Libs
import { useState } from "react";
import { useNavigate } from "react-router";
import { CircularProgressbar } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";

// Hooks
import useInterval from "../../hooks/useInterval";

// Components
import AnalysisData from "./AnalysisData";

import classes from "./styles.module.scss";

const FinishTouches = () => {
  const navigate = useNavigate();
  const [count, setCount] = useState(0);

  useInterval(() => {
    if (count === 100) {
      setTimeout(() => {
        navigate("/join-communites");
      }, 1000);
      return;
    }
    setCount(count + 1);
  }, 83);

  return (
    <div className={classes.page}>
      <p className={classes.title}>Almost there...</p>
      <div className={classes.progressBarWrapper}>
        <CircularProgressbar
          strokeWidth={2.5}
          styles={{
            trail: {
              color: "#436A61",
              stroke: "#D9D9D9",
            },
            root: {
              borderRadius: "50%",
              backgroundColor: "white",
            },
            path: {
              stroke: "#9D4545",
              color: "#9D4545",
            },
            text: {
              fontWeight: "700",
              fill: "black",
              fontSize: "18px",
            },
          }}
          value={count}
          text={`${count}%`}
        />
      </div>
      <div className={classes.analysisWraper}>
        <AnalysisData interval={1660} text="Evaluating your profile" />
        <AnalysisData interval={3320} text="Analyzing your lifestyle" />
        <AnalysisData interval={4980} text="Exploring selected issues" />
        <AnalysisData interval={6640} text="Determining your metabolic age" />
        <AnalysisData interval={8300} text="Developing a personal program" />
      </div>
    </div>
  );
};

export default FinishTouches;
