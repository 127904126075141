import React from "react";
import classes from "./styles.module.scss";

export const Button = ({
  children,
  id,
  onClick,
  disabled = false,
  inverted = false,
  type = "button",
}) => {
  return (
    <button
      id={id}
      type={type}
      onClick={onClick}
      disabled={disabled}
      className={
        inverted ? `${classes.button} ${classes.inverted}` : `${classes.button}`
      }
    >
      {children}
    </button>
  );
};
