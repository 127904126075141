import {
  AreasToFocus,
  GenderSelector,
  SkinProblemsSelector,
  WhatIsYourAge,
  LookYounger,
  ImproveJawline,
  SkincareRoutine,
  SkincareProducts,
  SpfLotions,
  Water,
  Sleep,
  Food,
  Vegetables,
  Acne,
  Stress,
  OilyShine,
  Wrinkles,
  Sagging,
  Lifestyle,
  BusyDuringTheDay,
  AfraidToLoseBeauty,
} from "../components";

import {
  MakePlanBetterBreakdown,
  FirstChangesBreakdown,
  WellDoneBreakdown,
  ResultsBreakdown,
} from "../../breakdowns";

export const QuestionsMap = {
  q1: ResultsBreakdown,
  q2: GenderSelector,
  q3: WhatIsYourAge,
  q4: SkinProblemsSelector,
  q5: AreasToFocus,
  q6: LookYounger,
  q7: ImproveJawline,
  q8: MakePlanBetterBreakdown,
  q9: SkincareRoutine,
  q10: SkincareProducts,
  q11: SpfLotions,
  q12: FirstChangesBreakdown,
  q13: Water,
  q14: Sleep,
  q15: Food,
  q16: Vegetables,
  q17: Acne,
  q18: Stress,
  q19: OilyShine,
  q20: Wrinkles,
  q21: Sagging,
  q22: WellDoneBreakdown,
  q23: Lifestyle,
  q24: BusyDuringTheDay,
  q25: AfraidToLoseBeauty,
};
