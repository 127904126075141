import React from "react";
import { ButtonWithAnswer } from "../../../shared/components/buttonWithAnswer";
import { QuestionsContainer } from "../../../shared/components/questionContainer";
import { ButtonsGroup } from "../../../shared/components/buttonsGroup";

export const GenderSelector = ({ onNextStep }) => {
  return (
    <QuestionsContainer title="What is your gender?">
      <ButtonsGroup>
        <ButtonWithAnswer onClick={onNextStep}>Woman</ButtonWithAnswer>
        <ButtonWithAnswer onClick={onNextStep}>Man</ButtonWithAnswer>
        <ButtonWithAnswer onClick={onNextStep}>
          Prefer not to say
        </ButtonWithAnswer>
      </ButtonsGroup>
    </QuestionsContainer>
  );
};
