import { ResultComponent } from "./CommonResultComponent";

export const Reject = () => {
  <ResultComponent
    icon={
      <svg
        width="26"
        height="26"
        viewBox="0 0 26 26"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M23.75 2.25L2.25 23.75" stroke="#9C0000" stroke-width="4" />
        <path d="M2.25 2.25L23.75 23.75" stroke="#9C0000" stroke-width="4" />
      </svg>
    }
    text="Payment issue"
    textButton={"Try again"}
    link={"/paywall"}
  />;
};
