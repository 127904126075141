import reviewsAvatar1 from "../../../assets/reviewsAvatar1.png";
import reviewsAvatar2 from "../../../assets/reviewsAvatar2.png";
import reviewsAvatar3 from "../../../assets/reviewsAvatar3.png";
import star from "../../../assets/star.png";

const ADVANTAGES_DATA = [
  {
    name: "Sets of facial exercises developed by professionals",
  },
  {
    name: "Personal care plan",
  },
  {
    name: "Unique yoga routines",
  },
  {
    name: "Additional tips & tricks for illuminating appearance",
  },
  {
    name: "Support and help from the community",
  },
];

const EXPECT_BLOCK_DATA = [
  {
    name: "Even and shiny skin",
  },
  {
    name: "Reduced puffiness",
  },
  {
    name: "Better blood circulation",
  },
  {
    name: "Easier lymph flow",
  },
  {
    name: "Well-rested appearance ",
  },
];

const SELECT_PLAN_DATA = [
  {
    id: 0,
    paymentID: "00000000-0000-0000-0000-000000000001",
    title: "User’s top choice",
    timeInterval: "1 month",
    discount: "-30%",
    integerPrice: 0,
    floatPrice: 67,
    monthPrice: 21,
    planTrialDescrtiption: false,
  },
  {
    id: 1,
    paymentID: "00000000-0000-0000-0000-000000000002",
    title: "Best offer",
    timeInterval: "3 month",
    discount: "-30%",
    integerPrice: 0,
    floatPrice: 33,
    monthPrice: 30,
    planTrialDescrtiption: true,
  },
];

const REVIEWS_DATA = [
  {
    name: "Linda A .",
    avatar: reviewsAvatar1,
    comment:
      "Only 10 minutes a day for a couple of months and my face is already transformed. Incredible!",
    stars: Array.from({ length: 5 }),
    starImage: star,
  },
  {
    name: "Mary J.",
    avatar: reviewsAvatar2,
    comment:
      "Great sets of exercises: easy to understand and very effective. Love it",
    stars: Array.from({ length: 5 }),
    starImage: star,
  },
  {
    name: "Sam K.",
    avatar: reviewsAvatar3,
    comment:
      "Blown away by the fact that my double chin disappeared! Thank you",
    stars: Array.from({ length: 5 }),
    starImage: star,
  },
];

const QUESTIONS_DATA = [
  {
    title: "How does it work?",
    description:
      "First step is to answer some questions about the issues you’re experiencing and the areas you want to work on. Based on your answers Face Yoga will create a personal schedule and sets of exercises to guarantee your appearance a second youth. It’s all done by using safe and proven methods including delicate massages and corrective practices.",
  },
  {
    title: "When will I see the first results?",
    description:
      "The first changes will be visible only days after starting the program! After 7-10 days swelling will go down, your skin will become more tight and face and neck area will appear slimmer. Keep up the steady work and observe the transformation.",
  },
  {
    title: "Which plan do I choose?",
    description:
      "It has been proven that at least a month of facial exercises will change the way you look and make you appear several years younger! However if you want to strengthen the effect, improve the health of your skin by doing regular exercises and staying consistent it’s easier and more convenient to choose an annual plan.",
  },
];

export {
  ADVANTAGES_DATA,
  EXPECT_BLOCK_DATA,
  SELECT_PLAN_DATA,
  REVIEWS_DATA,
  QUESTIONS_DATA,
};
