import classes from "./styles.module.scss";

const FaceYogaAdvantages = ({ data }) => {
  return (
    <ul className={classes.advantageList}>
      {data.map(({ name }, index) => (
        <li key={name + index} className={classes.advantageItem}>
          {name}
        </li>
      ))}
    </ul>
  );
};

export default FaceYogaAdvantages;
