// Libs
import { useNavigate } from "react-router";
import back from "../../assets/back.png";

import classes from "./styles.module.scss";

const PrivacyPolicy = () => {
  const navigate = useNavigate();

  return (
    <div className={classes.page}>
      <div className={classes.headerWrapper}>
        <button className={classes.headerButton} onClick={() => navigate(-1)}>
          <img src={back} alt="" />
        </button>
        <h1>Privacy Policy</h1>
      </div>
      <br />
      <p>Last updated December 23, 2022</p>
      <br />
      <p>
        This Privacy Policy is aimed to inform you (“You”) as a user of the
        faceyoga.health, a service available via the link{" "}
        <a
          href="https://faceyoga.health/"
          target="_blank"
          rel="noopener noreferrer"
        >
          https://faceyoga.health/
        </a>{" "}
        (“Website”), called to as the “Service”, about collection, usage,
        sharing of Your information through the Website - by Straiton Limited
        (HE 371332), a company having its registered address at Alpha Tower,
        Office 11, 3021, Limassol, Cyprus further in the text, “we”, “us”,
        “our”, acting as a data controller for your data.
      </p>
      <br />
      <p>
        You are also hereby informed on the options available to You in terms of
        usage and disclosure and Your information by us. This Privacy Policy is
        incorporated into, and is subject to, the Service Terms of Use.
      </p>
      <br />
      <p>
        By choosing to use our Service You: (I) agree to the collection and use
        of information in relation to this Privacy Policy, (II) warrant and
        guarantee that You are over 16 years of age (or are a parent or guardian
        with such authority to agree to this Privacy Policy for the benefit of
        an individual under 16 years of age). Please do not use the Service or
        access the services provided by it if You do not agree to this Privacy
        Policy.
      </p>
      <br />
      <h2>What kind of information do we collect?</h2>
      <p>
        We may collect information that can identify You, such as Your name and
        email address (“Personal Information”), and other information that does
        not directly identify You, as listed below
      </p>
      <br />
      <ol type="I">
        <li>
          Information You Provide
          <ul>
            <li>
              A. First name, email address, gender, date of birth, sleep
              condition, wellbeing details, product feedback, communication
              details for feedback collection. We may collect and store the
              above-mentioned information when You register and use the Service
              or provide it to us in some other manner, for example by
              corresponding with us by email when You report a problem with the
              Service or when You subscribe to any of our services or search for
              a service.
            </li>
            <br />
            <li>Note: Information about a third party</li>
            <br />
            <li>
              When You provide us with information about a third party, You must
              have the consent of that third party before providing us with that
              information. By providing us with information regarding a third
              party You warrant that You have their permission to provide that
              information to us.
            </li>
          </ul>
        </li>
        <br />
        <li>
          Information collected automatically
          <br />
          <ol type="A">
            <li>
              Device information: may include information specific to Your
              mobile device (e.g., OS Version, hardware, unique device
              identifier, mobile network information); information about Your
              logging in, use of features, functions, or notifications on the
              device. We also collect and use Your Apple Identifier for
              Advertising (IDFA) and Google Advertising ID (AAID) to recognize
              Your device and support advertising activities on our Services.
              These number values are not permanently tied to Your device and,
              depending on Your operating system, You can reset it through Your
              device settings.
            </li>
            <br />
            <li>
              Usage information: Information collected automatically from
              Site/Account (or third party in connection with the Services \),
              which can include: the IP addresses or domain names of the
              computers utilized by the Users who use this Application, the URI
              addresses (Uniform Resource Identifier), the time of the request,
              the method utilized to submit the request to the server, the size
              of the file received in response, the numerical code indicating
              the status of the server's answer (successful outcome, error,
              etc.), the country of origin, the features of the browser and the
              operating system utilized by the User, the various time details
              per visit (e.g., the time spent on each page within the
              Application) and the details about the path followed within the
              Application with special reference to the sequence of pages
              visited, and other parameters about the device operating system
              and/or the User's IT environment.
            </li>
            <br />
            <p>
              If You purchase the access to or otherwise use the Service through
              the Website, we may additionally collect the following data:
            </p>
            <br />
            <li>
              Transaction data: when You make payments through the Website, You
              need to provide financial account data, such as Your credit card
              number, residence address, to our third-party service providers.
              We do not collect or store full credit card number data, though we
              may receive credit card-related data, data about the transaction,
              including: date, time and amount of the transaction, the type of
              payment method used, payment transaction identification number.
            </li>
            <br />
            <li>
              Cookies. We may use cookies in a range of ways to improve the Your
              experience on the Website, including understanding how You use the
              Website and approving Your Website usage experience.
            </li>
            <br />
          </ol>
        </li>
      </ol>
      <h2>What do we collect this information for?</h2>
      <br />
      <ul>
        <li>
          Enhancing Your user experience on our Service by operating,
          maintaining, and providing You with all of the content and features of
          the Service (“maintaining Service functionality purposes”);
        </li>
        <br />
        <li>
          managing Your account and providing You with customer support. We may
          use Your Personal Information to respond to Your requests for
          technical support, Service information or to any other communication
          You initiate (“customer support purposes”);
        </li>
        <br />
        <li>
          performing research and analysis about Your use of, or interest in,
          our products, services or content, or products, services or content
          offered by others (“analytics purposes”);
        </li>
        <br />
        <li>
          communicating with You via push notifications, reminders about the
          Service, Service updates and relevant to Service information. You can
          opt out of receiving push notifications by changing the settings on
          Your device or in Your browser;
        </li>
        <br />
        <li>
          detecting and resolving any fraud or other illegal or undesirable user
          behavior;
        </li>
        <br />
        <li>
          arranging of advertising campaigns, analysis of advertising campaigns
          effectiveness (“marketing purposes”) to the extent permitted by the
          law;
        </li>
        <br />
        <li> taking legally required actions, defense from legal claims.</li>
        <br />
      </ul>
      <p>
        As regards emails, during in-app registration/onboarding process we may
        ask you to login into the app based on the email in order to create an
        account/save your progress. In this regard, we will process your email
        data because we need to perform a contract with you. As regards phone
        numbers, we may use it for service-related communications.
      </p>
      <br />
      <p>
        In addition to above, we may also use your email address to verify
        whether such email is correct; let you know about important updates to
        our terms and policies; communicate with you about our Service or
        similar products that might be of interest for you; ask for your
        feedback. Should you opt-in (or we are allowed based on your country
        laws), we may also send you other marketing communications, such as
        product updates, offers, discounts, and provide news and information
        that we think will be of interest to you. All email communications are
        performed based on our legitimate interest.
      </p>
      <br />
      <p>
        Please note that when we process your email data on the basis of
        legitimate interests for direct marketing purposes, you have an
        unrestricted right to object to the processing; this also applies to
        matching and attribution. Should you wish to exercise your right to
        object, please email us via any of the contact options available below.
      </p>
      <br />
      <p>
        In a very limited number of cases depending on application store’s
        requirements, we may also use your phone number for matching and
        attribution. Such marketing activities are also based on our legitimate
        interest.
      </p>
      <br />
      <p>
        With respect to Your purchase of the access to the Service through the
        Website, Your information may be collected to process Your payments as
        described in detail below.
      </p>
      <br />
      <p>
        We provide paid Content (as defined in the{" "}
        <a
          href="https://faceyoga.health/terms-of-use"
          target="_blank"
          rel="noopener noreferrer"
        >
          Terms of Use
        </a>{" "}
        ) within the Service. For this purpose, we use third-party services for
        payment processing (for example, payment processors). As a result of
        this processing, You will be able to make a payment for the Content and
        we will be notified that the payment has been made and either completed
        or not completed.
      </p>
      <br />
      <p>
        Content and we will be notified that the payment has been made and
        either completed or not completed.
      </p>
      <br />
      <p>
        We will not store or collect Your payment card details ourselves. This
        information will be provided directly to our third-party payment
        processors, referred to in the next section. Our payment processors
        adhere to the standards set by PCI-DSS as managed by the PCI Security
        Standards Council. Payment processors who follow PCI-DSS requirements
        are committed to ensuring the secure handling of payment information.
      </p>
      <br />
      <h2>
        With whom and what do we share this information for and how may we
        disclose it
      </h2>
      <br />
      <ol type="I">
        <li>
          {" "}
          Service Providers
          <br />
          <p>
            We may share Your Personal Information with third parties that
            perform certain services on our behalf, such as our authorized
            service providers or analytics providers. In general, the
            third-party providers engaged by us will only collect, use and
            disclose Your information to the extent necessary to allow them to
            perform the services they provide to us, e.g. facilitating our
            Service, arranging marketing campaigns on Our behalf and analyzing
            how our Service is used.
          </p>
          <br />
          <p>
            You can learn more about main service providers with whom we may
            share Your Personal Information as described above hereunder, along
            with links to such service providers privacy policies, which we
            recommend You read, depending on whether You use the Website or the
            App.
          </p>
          <br />
          <h4>SERVICE PROVIDERS AS TO WEBSITE</h4>
          <br />
          <ol type="1">
            <li>
              <b>Amazon Web Services</b>
              <p>
                We use Amazon Web Services, which is a hosting and backend
                service provided by Amazon, for personal data hosting and
                enabling our Service to operate and be distributed to its users.
              </p>
            </li>
            <br />
            <li>
              <b>AppsFlyer</b>
              <p>
                We use AppsFlyer for analytics purposes, specifically for
                research and analysis of users interaction with the Website.
                Appsflyer helps us to understand, in particular, how users find
                our Website (for example, which advertiser served an ad that led
                You to our Website). Appsflyer also provides us with different
                analytics tools that enable us to research and analyze Your use
                of the Service
              </p>
              <br />
              <div>
                In order to receive these services from Appsflyer, we share such
                data as:
                <ol type="a">
                  <li>
                    {" "}
                    IP address (which may also provide general location
                    information), user agent, Google Advertiser ID and other
                    similar unique identifiers (“Technical Identifiers”).
                  </li>
                  <br />
                  <p>
                    AppsFlyer Privacy Policy is available via the link:
                    <a
                      href="https://www.appsflyer.com/privacy-policy/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      https://www.appsflyer.com/privacy-policy/
                    </a>
                    . Please note that You may cancel collection of Your
                    Personal Information for and sharing it with AppsFlyer here:
                    <a
                      href="https://www.appsflyer.com/optout"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      https://www.appsflyer.com/optout
                    </a>
                    .
                  </p>
                </ol>
              </div>
            </li>
            <br />
            <li>
              <b>Facebook Pixel</b>
              <p>
                (if data processing concerns EU, UK citizens and{" "}
                <a
                  href="https://www.facebook.com/legal/terms/businesstools"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  certain other jurisdictions
                </a>
                : by Facebook Ireland Ltd., 4 Grand Canal Square, Grand Canal
                Harbour, Dublin 2 Ireland; for processing of data originating
                from other jurisdictions: Facebook, Inc., 1 Hacker Way, Menlo
                Park, CA 94025, USA)
              </p>
              <br />
              <p>
                The Facebook pixel is an analytics tool that allows us to
                measure the effectiveness of our advertising by understanding
                the actions the users take within the Service. Facebook may
                collect or receive information from our Product and use that
                information to provide measurement services and targeted ads.
              </p>
              <br />
              <p>
                Please mind that you may opt-out of the collection and use of
                information for ad targeting. Options you may use in order to
                opt-out, are described below in this Privacy Policy. Some of the
                mechanisms for exercising the choice to opt out are via the
                links:{" "}
                <a
                  href="http://www.aboutads.info/choices"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  http://www.aboutads.info/choices
                </a>{" "}
                and{" "}
                <a
                  href="http://www.youronlinechoices.eu/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  http://www.youronlinechoices.eu/
                </a>
                .
              </p>
            </li>
            <br />
            <li>
              <b>Customer.io</b>
              <p>
                In order to deliver customized emails, web push-notifications or
                SMS-notifications to our customers, we transfer customer-related
                data, such as e-mail address, phone number, device information,
                payment status, subscription date and user’s precise location to
                a Customer.io – marketing personalization platform
              </p>
              <br />
              <p>
                You can access the Customer.io Privacy Policy via the link:
                <a
                  href="https://customer.io/legal/privacy-policy/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  https://customer.io/legal/privacy-policy/
                </a>
              </p>
            </li>
            <br />
            <li>
              <b>Google Analytics/Google Optimize</b>
              <p>
                In order to keep the content of the Web-site up-to-date,
                user-oriented and complete, we use Google Analytics and Google
                Optimize, web-analytics tools which enable us to optimize our
                services, including advertising, to Your needs. We transfer
                certain data, related to You, to mentioned services, however
                such data is anonymized, i.e. Google Analytics/Google Optimize
                shall not be able to identify You based on the data they are
                going to have access to.
              </p>
              <br />
              <p>
                You can learn more about the Google Analytics Privacy Policy
                here:
                <a
                  href="https://policies.google.com/privacy"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  https://policies.google.com/privacy
                </a>
              </p>
            </li>
            <li>
              <b>Advertising services providers</b>
              <p>
                We engage certain third-party service providers in order to help
                us spread the word about the Service and help the Service reach
                its potential users through reports and insights on how to
                optimize our promotional campaigns. For these providers, we
                recommend that You read their privacy policies so You can
                understand the manner in which Your Personal Information will be
                handled by these providers:
              </p>
              <br />
              <ol type="a">
                <li>
                  Snapchat{" "}
                  <a
                    href="https://www.snap.com/en-US/privacy/privacy-center"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    https://www.snap.com/en-US/privacy/privacy-center
                  </a>
                </li>
                <li>
                  Meta{" "}
                  <a
                    href="https://www.facebook.com/privacy/policy/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    https://www.facebook.com/privacy/policy/
                  </a>
                </li>
                <li>
                  Google AdWords{" "}
                  <a
                    href="https://policies.google.com/privacy"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    https://policies.google.com/privacy
                  </a>
                </li>
                <li>
                  TikTok{" "}
                  <a
                    href="https://www.tiktok.com/legal/privacy-policy?lang=en"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    https://www.tiktok.com/legal/privacy-policy?lang=en
                  </a>
                </li>
              </ol>
            </li>
            <li>
              <b>Payment services providers</b>
              <p>
                Certain third-party service providers, such as payment gateways
                and other payment transaction processors, have their own privacy
                policies with respect to the information we are required to
                provide to them for Your purchase-related transactions. For
                these providers, we recommend that You read their privacy
                policies so You can understand the manner in which Your Personal
                Information will be handled by these providers:
              </p>
              <br />
              <ol type="a">
                <li>
                  PayPro Corporation{" "}
                  <a
                    href="https://www.payprocorp.com/about-us/privacy-policy/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    (https://www.payprocorp.com/about-us/privacy-policy/);
                  </a>
                </li>
                <li>
                  Ecommpay Limited{" "}
                  <a
                    href="https://ecommpay.com/privacy-policy/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    (https://ecommpay.com/privacy-policy/);
                  </a>
                </li>
              </ol>
            </li>
          </ol>
        </li>
        <br />
        <h4>SERVICE PROVIDERS AS TO THE APP</h4>
        <br />
        <ol type="1">
          <li>
            <b>Amazon Web Services</b>
            <p>
              We use Amazon Web Services (“AWS”), which is a hosting and backend
              service provided by Amazon, for personal data hosting and enabling
              our Application to operate and be distributed to its users.
            </p>
          </li>
          <li>
            <b>AppsFlyer</b>
            <p>
              We use AppsFlyer for analytics purposes, specifically for research
              and analysis of users' interaction with the Application. Appsflyer
              helps us to understand, in particular, how users find our App (for
              example, which advertiser served an ad that led You to our App in
              AppStore or GooglePlay). Appsflyer also provides us with different
              analytics tools that enable us to research and analyze Your use of
              the Service.
            </p>
            <br />
            <p>
              In order to receive these services from Appsflyer, we share such
              data as:
            </p>
            <ol type="a">
              <li>
                device type and model, CPU, system language, memory, OS version,
                Wi-Fi status, time stamp and zone, device motion parameters and
                carrier (“Technical Information”);
              </li>
              <br />
              <li>
                P address (which may also provide general location information),
                User agent, IDFA (Apple identifier for advertisers), Android ID
                (in Android devices), Google Advertiser ID and other similar
                unique identifiers (“Technical Identifiers”); You can learn more
                about the AppsFlyer Privacy Policy here:
                <a
                  href="https://www.appsflyer.com/privacy-policy/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  https://www.appsflyer.com/privacy-policy/
                </a>
                . Please note that You may cancel collection of Your Personal
                Information for and sharing it with AppsFlyer here:{" "}
                <a
                  href="https://www.appsflyer.com/optout"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  https://www.appsflyer.com/optout
                </a>
                .
              </li>
            </ol>
          </li>
          <br />
          <li>
            <b>Amplitude</b>
            <p>
              We use Amplitude for analytics and customer support purposes,
              specifically to understand how customers use our App.
            </p>
            <br />
            <p>
              As for analytics purposes, Amplitude helps us to understand, in
              particular, how users find our App (for example, which advertiser
              had delivered an ad that led You to our App in App store).
              Amplitude also provides us with different analytics tools that
              enable us to research and analyze Your use of the Service, which
              helps us to decide what Application features we should focus on.
            </p>
            <br />
            <p>
              As for customer support purposes, Amplitude helps us to respond to
              Your requests for technical support. For example, it enables us to
              track Your interaction with our App and in order to detect
              particular technical issues with the App.
            </p>
            <br />
            <p>
              In order to receive these services from Amplitude, we share such
              data as:
            </p>
            <br />
            <ol type="a">
              <li>
                Platform, device type (phone or tablet), device family, country,
                city, region, carrier, OS, time zone, language, IP Address,
                locale, referral source (“technical information”);
              </li>
              <br />
              <li>
                Your interaction with the App (i.e. taps on the particular
                screens) and age, gender, email, (“user related information”);.
              </li>
              <br />
              <li>Unique identifiers (such as IDFA).</li>
            </ol>
            <p>
              You can learn more about the Amplitude Privacy Policy here:{" "}
              <a
                href="https://amplitude.com/privacy"
                target="_blank"
                rel="noopener noreferrer"
              >
                https://amplitude.com/privacy
              </a>
              .
            </p>
          </li>
          <br />
          <li>
            <b>Firebase</b>
            <p>
              We use a number of Firebase services with different levels of
              access to your data. Please see below the details:
            </p>
            <br />
            <p>
              <b>
                Firebase Cloud Messaging (powered by Google Ireland Limited,
                with offices at Gordon House, Barrow Street, Dublin 4, Ireland)
              </b>
            </p>
            <br />
            <p>
              We use Google-powered service Firebase Cloud Messaging in order to
              deliver in-app messages (notifications or data messages) which may
              be of interest for you, as well as analyze effectiveness of such
              in-app messages, check their delivery status so that such messages
              are as relevant as possible (through Firebase Analytics).
            </p>
            <br />
            <p>
              Firebase Cloud Messaging collects your user-related data to
              process the delivery of in-app messages and allows us to assess
              their effectiveness. Such user-related data may include
              characteristics of your device, advertising identifier, app store,
              device’s language, OS version, your age, interactions with
              notifications, data messages, status of messages delivery etc,
              subscription status and some other data.
            </p>
            <br />
            <p>
              <b>
                Google Analytics for Firebase (powered by Google Ireland
                Limited, with offices at Gordon House, Barrow Street, Dublin 4,
                Ireland)
              </b>
            </p>
            <br />
            <p>
              As to iOS devices, we use Google Analytics for Firebase in order
              to assess app performance/minimize crashes, as well to assess
              effectiveness of messaging campaigns in complex with Firebase
              Crashlytics and Firebase Cloud Messaging respectively.
              User-related data transferred with regard to mentioned processing
              may include characteristics of your device, advertising
              identifier, app store, device’s language, OS version, your age,
              interactions with notifications, data messages, status of messages
              delivery etc, subscription status and some other data.
            </p>
            <br />
            <p>
              For Android devices, we use Google Analytics for Firebase not only
              to assess app performance/minimize crashes and access messaging
              campaigns effectiveness, but also to assess effectiveness of our
              advertising campaigns. In this regard, we may share/receive some
              of your additional data with/from Google, for example your Android
              Advertising Identifier, IP address, fact of purchase, interactions
              with ads.
            </p>
          </li>
          <br />
          <li>
            <b>Facebook Conversion API</b>
            <p>
              (if data processing concerns EU, UK citizens and{" "}
              <a
                href="https://www.facebook.com/legal/terms/businesstools"
                target="_blank"
                rel="noopener noreferrer"
              >
                certain other jurisdictions
              </a>
              : by Facebook Ireland Ltd., 4 Grand Canal Square, Grand Canal
              Harbour, Dublin 2 Ireland; for processing of data originating from
              other jurisdictions: Facebook, Inc., 1 Hacker Way, Menlo Park, CA
              94025, USA)
            </p>
            <br />
            <p>
              We use Facebook Conversion API in order to maximize effectiveness
              of our marketing/advertising campaigns, measurement services and
              targeted ads. At that, Facebook may collect or receive certain
              information from the App, for example, information on your
              interaction within the App.
            </p>
            <br />
            <p>
              Please mind that you may opt-out of the collection and use of
              information for ad targeting. Options you may use in order to
              opt-out, are described below in this Privacy Policy. Some of the
              mechanisms for exercising the choice to opt out are via the links:
              <a
                href="http://www.aboutads.info/choices"
                target="_blank"
                rel="noopener noreferrer"
              >
                http://www.aboutads.info/choices
              </a>{" "}
              and
              <a
                href="http://www.youronlinechoices.eu/"
                target="_blank"
                rel="noopener noreferrer"
              >
                http://www.youronlinechoices.eu/
              </a>
              .
            </p>
          </li>
        </ol>
        <br />
        <li>
          Law enforcement agencies and other public authorities
          <p>
            We may use and disclose personal data to enforce our Terms of Use,
            which can be found here:{" "}
            <a
              href="https://faceyoga.health/terms-of-use"
              target="_blank"
              rel="noopener noreferrer"
            >
              Terms of Use
            </a>
            , to protect our rights, privacy, safety, or property, and/or that
            of our affiliates, you or others, and to respond to requests from
            courts, law enforcement agencies, regulatory agencies, and other
            public and government authorities, or in other cases provided for by
            law.
          </p>
        </li>
        <br />
        <li>
          Third parties as part of a merger or acquisition
          <p>
            As we develop our business, we may buy or sell assets or business
            offerings. Information on customers is generally one of the
            transferred business assets in these types of transactions. We may
            also share such information with any affiliated entity (e.g. parent
            company or subsidiary) and may transfer such information in the
            course of a corporate transaction, such as the sale of our business,
            a divestiture, merger, consolidation, or asset sale, or in the
            unlikely event of bankruptcy.
          </p>
          <br />
          <p>
            <b>International Data Transfers</b>
          </p>
          <br />
          <p>
            Please note that our business is global, so when You provide
            Personal Information through our Service, the information may be
            sent to servers located in different countries around the world.
          </p>
          <br />
          <h3>HOW YOU CAN ACCESS YOUR INFORMATION</h3>
          <br />
          <p>
            Your use of the Service, You may use the Service to do so. If You
            need help with rectification of data You think is inaccurate, please
            contact us via the applicable email specified in section “Contact
            Us” below.
          </p>
          <br />
          <p>
            You can erase Your Personal Information through the Profile menu of
            the Service. Please note that it may affect the Services provided,
            and You will not be able to use the full scope of the Services. When
            we delete any information, it will be deleted from the active
            database, but it may remain in our archives due to legal
            obligations. Should You require the deletion of such Personal
            Information from our archives, please contact us at the email
            specified in section “Contact Us” below. Please note that we may be
            required to keep such information in our archives and not delete it
            (or to keep this information for a certain time, in which case we
            will comply with Your deletion request only after we have fulfilled
            such requirements). Please also note that the erasing of Your
            Personal Information does not mean the automatic cancellation of
            Your subscription purchased via the App or the Website or refund of
            Your payments.
          </p>
          <br />
          <p>
            You can make a request to receive the Personal Information related
            to You through contacting us via support@faceyoga.health. Should You
            make such a request, You will be required to provide us with an
            email address, which we would be able to send Your Personal
            Information to. Please note that You confirm and guarantee that the
            email address You provide to us in order to receive Your Personal
            Information shall belong to You and You shall be solely responsible
            for its correctness and validity and therefore we shall in any case
            not be liable for any breach of Your Personal Information due to
            sending it to that email address.
          </p>
          <br />
          <p>
            If You reside in the European Economic Area You may object to the
            processing of Personal Information concerning You for marketing
            purposes by contacting us at the email specified in section “Contact
            Us” below and the related Personal Information shall no longer be
            processed for such purposes.
          </p>
          <br />
          <p>
            Please note that You may also opt out from collection of Your data
            for marketing and advertising purposes not only via our App, but
            also on Your entire device using:
          </p>
          <br />
          <p> -&gt; Your device settings</p>
          <br />
          <ul>
            <li>
              iOS: go to “Settings” -&gt; “Privacy” -&gt; “Advertising” -&gt;
              “Limit Ad Tracking”. You may additionally reset Your advertising
              identifier (this also may help You to see less of personalized
              ads) in the same section.
            </li>
            <br />
            <li>
              Android: for Android 6.0 and higher – go to “Settings” -&gt;
              “Google” -&gt; “Ads” -&gt; ”Opt out of interest-based ads”. For
              Android 5.2 and lower, use Google Settings app -&gt; “Ads” -&gt;
              ”Opt out of interest-based ads”.
            </li>
          </ul>
          <br />
          <p>
            You may additionally reset Your advertising identifier (this also
            may help You to see less of personalized ads) in the same section
            for both options mentioned.
          </p>
          <br />
          <p> -&gt; and links below:</p>
          <br />
          <ul>
            <li>
              Network Advertising Initiative –{" "}
              <a
                href="http://optout.networkadvertising.org/"
                target="_blank"
                rel="noopener noreferrer"
              >
                http://optout.networkadvertising.org/
              </a>
            </li>
            <br />
            <li>
              Digital Advertising Alliance –{" "}
              <a
                href="http://optout.aboutads.info/"
                target="_blank"
                rel="noopener noreferrer"
              >
                http://optout.aboutads.info/
              </a>
            </li>
            <br />
            <li>
              Digital Advertising Alliance (Canada) –
              <a
                href="http://Youradchoices.ca/choices"
                target="_blank"
                rel="noopener noreferrer"
              >
                http://Youradchoices.ca/choices
              </a>
            </li>
            <br />
            <li>
              Digital Advertising Alliance (EU) –{" "}
              <a
                href="http://www.Youronlinechoices.com/"
                target="_blank"
                rel="noopener noreferrer"
              >
                http://www.Youronlinechoices.com/
              </a>
            </li>
            <br />
            <li>
              DAA AppChoices page –{" "}
              <a
                href="http://www.aboutads.info/appchoices"
                target="_blank"
                rel="noopener noreferrer"
              >
                http://www.aboutads.info/appchoices
              </a>
            </li>
          </ul>
          <br />
          <p>
            Please note that the result of such objection or cancellation may
            affect the process of the Services improvement. However, You would
            still be able to use the App
          </p>
          <br />
          <p>
            If You think that the Personal Information related to You is
            inaccurate, but You can’t rectify it as described above, You may
            contact us at the email specified in section “Contact Us” below to
            restrict the processing of such data for the period of time we need
            to verify the accuracy of the Personal Information, which we will
            inform You of.
          </p>
          <br />
          <p>
            As to the App, You can stop all collection of information by the
            Application by uninstalling the Application from Your mobile device.
            You may use the standard uninstall processes as may be available as
            part of Your mobile device or via the Application marketplace or
            network.
          </p>
          <br />
          <h3>How We Secure Your information</h3>
          <br />
          <p>
            Security is our highest priority. Our Service has been designed with
            Your security and privacy in mind.
          </p>
          <br />
          <ul>
            <li>
              We protect the security of Your information by using encryption
              protocols and software;
            </li>
            <br />
            <li>
              We aggregate and pseudonymize all of the Users Personal
              Information;
            </li>
            <br />
            <li>
              We maintain physical, electronic and procedural safeguards in
              connection with the collection, storage and disclosure of Personal
              Information;
            </li>
            <br />
            <li>
              We employ Personal Information systematic backups, which are
              intended to reduce the risk from disk crashes and errors, such as
              accidental information deletion
            </li>
          </ul>
          <br />
          <p>
            While we cannot ensure or guarantee that loss, misuse or alteration
            of information will never occur, we make all reasonable efforts to
            prevent it. However, You should bear in mind that submission of
            information over the internet is never entirely secure. Please note
            that we do not collect and we are not responsible for the collection
            or security of Your payment details.
          </p>
        </li>{" "}
        <br />
        <h3>California residents</h3> <br />
        <p>
          Individuals residing in California have certain statutory rights in
          relation to their Data introduced by California Consumer Privacy Act
          of 2018 (the “CCPA”). Subject to any exemptions provided by law, you
          may have the following rights:
        </p>{" "}
        <br />
        <b>a. Transparency Rights</b> <br /> <br />
        <p>
          A right to receive certain information about how a business collects
          and uses your information. Such information must be provided within a
          privacy policy and upon request.
        </p>{" "}
        <br />
        <b>b. Right of Access</b> <br /> <br />
        <p>
          A right to request access to certain information which includes
          categories of personal information collected or sold, specific
          personal information, sources, purpose of collection and use and
          categories of third parties that the Business sold personal
          information to.
        </p>{" "}
        <br />
        <b>c. Portability Rights</b> <br /> <br />
        <p>
          The right to receive the information in a readily usable format that
          allows you to easily transmit the information to another entity.
        </p>{" "}
        <br />
        <b>d. Right of Deletion</b> <br /> <br />
        <p>
          A right to request deletion of data collected from you. Upon such a
          request, a business must delete such data (subject to certain
          exceptions) and flow down the request to its service providers who
          must comply.
        </p>{" "}
        <br />
        <b>e. Right to Opt Out</b> <br /> <br />
        <p>
          The right to require any business that sells information to third
          parties to opt out from selling your information. In the case of
          personal information of children between 13-16, a the child must opt
          in to permit such sale and under 13 must obtain parental consent.
        </p>{" "}
        <br />
        <b>f. Non-Discrimination Rights</b> <br /> <br />
        <p>
          Subject to certain exceptions businesses are prohibited from
          discriminating against any Consumer who exercises a right granted
          under the CCPA and must continue to provide equal service or goods and
          price even if one exercises his rights.
        </p>{" "}
        <br />
        <p>
          Please keep in mind that in case of a vague access, erasure, objection
          request or any other request in exercise of the mentioned rights we
          may engage the individual in a dialogue so as to better understand the
          motivation for the request and to locate responsive information.
        </p>{" "}
        <br />
        <p>
          Following the provisions of CCPA we might also require you to prove
          your identity (for example, by requesting an ID or any other proof of
          identity) in order for you to invoke the mentioned rights. This is
          made to ensure that no rights of third parties are violated by your
          request, and the rights described in this section are exercised by an
          actual Data subject or an authorized person.
        </p>{" "}
        <br />
        <h3>EU residents</h3>
        <p>
          {" "}
          <br />
          Individuals residing in the countries of the European Union have
          certain statutory rights in relation to their Data introduced by the
          General Data Protection Regulation (the “GDPR”). Subject to any
          exemptions provided by law, you may have the right to request access
          to Data (including in a structured and portable form), as well as to
          seek to update, delete or correct Data:
        </p>{" "}
        <br />
        <b>
          a. Rectification of Data and Restriction of Processing
        </b> <br /> <br />
        <p>
          You are responsible for ensuring the accuracy of your Data that you
          submit to the Application. Inaccurate information will affect your
          experience when using the Application and our ability to contact you.
          If you believe that your Data is inaccurate, you have the right to
          contact us and ask us to correct such Data by contacting us at
          support@faceyoga.health. You shall also have the right to request
          restriction of processing of your Data, if you contest the accuracy of
          the Data and we need some time to verify its accuracy.
        </p>{" "}
        <br />
        <b>b. Access to your Data and Data Portability</b> <br /> <br />
        <p>
          You shall have the right to request information about whether we have
          any Data about you, to access your Data (including in a structured and
          portable form) by writing to us at support@faceyoga.health.
        </p>{" "}
        <br />
        <b>c. Erasure of your Data</b> <br /> <br />
        <p>
          If you believe that your Data is no longer necessary in relation to
          the purposes for which it was collected or otherwise processed, or in
          cases where you have withdrawn your consent or object to the
          processing of your Data, or in cases where the processing of your Data
          does not otherwise comply with the GDPR, you have right to contact us
          and ask us to erase such Data as described above. You can simply write
          to us at support@faceyoga.health. Please be aware that erasing some
          Data inserted by you may affect your possibility to use the
          Application. Erasure of some Data may also take some time due to
          technical reasons.
        </p>
        <br />
        <b>d. Right to object processing of your Data</b>
        <br /> <br />
        <p>
          You can object processing your Data and stop us from processing your
          Data simply by writing to us at support@faceyoga.health. Please be
          aware that erasing some Data inserted by you may affect your
          possibility to utilize the Application.
        </p>{" "}
        <br />
        <b>e. Notification requirements</b> <br />
        <p>
          {" "}
          <br />
          We commit to notify you within a reasonable period of time and your
          data protection authority within the timeframe specified in applicable
          law about any personal data breaches in the Application.
        </p>{" "}
        <br />
        <b>f. Data Protection Authorities</b>
        <br />
        <br />
        <p>
          Subject to GDPR, you also have the right to (i) restrict our use of
          Data and (ii) lodge a complaint with your local data protection
          authority about any of our activities that you deem are not compliant
          with GDPR.
        </p>{" "}
        <br />
        <p>
          Please keep in mind that in case of a vague access, erasure, objection
          request or any other request in exercise of the mentioned rights we
          may engage the individual in a dialogue so as to better understand the
          motivation for the request and to locate responsive information.
        </p>{" "}
        <br />
        <p>
          Following the provisions of GDPR we might also require you to prove
          your identity (for example, by requesting an ID or any other proof of
          identity) in order for you to invoke the mentioned rights. This is
          made to ensure that no rights of third parties are violated by your
          request, and the rights described in this section are exercised by an
          actual Data subject or an authorized person.
        </p>{" "}
        <br />
        <h3>Children’s Privacy</h3> <br />
        <p>
          This Service does not address anyone under the age of 16. We do not
          knowingly collect personally identifiable information from children
          under 16. If we discover that a child under 16 has provided us with
          Personal Information, we shall take steps to delete such information
          and terminate the child’s account. If You are a parent or a guardian
          and You are aware that Your child has provided us with Personal
          Information, please contact us so that we will be able to take the
          necessary actions.
        </p>{" "}
        <br />
        <h3>Retention</h3> <br />
        <p>
          We will retain Your information for as long (i) as You have an account
          with the Service, or (ii) as the App is installed on Your mobile
          device, or (iii) as needed to provide You with our Service. We will
          also retain and use Your information, including copies of Your
          information, as necessary to comply with our legal obligations
          (including for tax and accounting purposes), resolve disputes, and
          enforce our agreements.
        </p>{" "}
        <br />
        <h3>Changes to This Privacy Policy</h3> <br />
        <p>
          We may update our Privacy Policy from time to time. Therefore, You are
          advised to check our Service occasionally to inform Yourself of any
          changes. We will notify You of any changes by posting the new Privacy
          Policy on this page. These changes are effective immediately after
          they are posted on this page. Your continued use of the Service after
          changes to the Privacy Policy have been posted will constitute Your
          acceptance of such changes.
        </p>{" "}
        <br />
        <h3>Contact Us</h3> <br />
        <p>
          Please submit any questions, concerns or comments You have about this
          Privacy Policy or any requests concerning Your Personal Information
          via the following email: support@faceyoga.health
        </p>
        <br />
        <h3>
          BY USING THIS APPLICATION, YOU PROMISE THAT YOU HAVE READ, UNDERSTAND
          AND AGREE TO THIS PRIVACY POLICY.
        </h3>
      </ol>
    </div>
  );
};

export default PrivacyPolicy;
