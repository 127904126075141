import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getSubscriptionPlans,
  openPayPal,
} from "../../../../redux/slices/subscriptionSlice";
import { setActivePlan } from "../../../../redux/slices/subscriptionSlice";

import classes from "./styles.module.scss";

const SelectPlan = ({ scrollRef }) => {
  const dispatch = useDispatch();
  const subscriptionPlans = useSelector((state) => state.subscription.plans);

  const selectedSubscriptionPlan = useSelector((state) =>
    state.subscription.plans.find((plan) => plan.active === true)
  );

  useEffect(() => {
    dispatch(getSubscriptionPlans());
  }, [dispatch]);

  const handleOpenPayPal = () =>
    dispatch(openPayPal(selectedSubscriptionPlan.id));

  return (
    <>
      {subscriptionPlans.map(
        ({
          id,
          billingPeriodUnit,
          billingPeriodDuration,
          trialPeriodDuration,
          trialPeriodPriceAmount,
          uiDisplayOptions,
          active,
        }) => (
          <div
            key={id}
            onClick={() => {
              dispatch(setActivePlan(id));
            }}
            className={classes.planBlock}
          >
            <div
              className={
                active
                  ? classes.planBlockTitleWrapper
                  : `${classes.planBlockTitleWrapper} ${classes.planBlockTitleWrapperNotActive}`
              }
            >
              <p className={classes.planBlockTitle}>
                {uiDisplayOptions?.title}
              </p>
            </div>
            <div className={classes.planBlockContentWrapper}>
              <div>
                <p
                  className={
                    active
                      ? classes.planBlockDate
                      : `${classes.planBlockDate} ${classes.planBlockDateNotActive}`
                  }
                >
                  {billingPeriodDuration + " " + billingPeriodUnit}
                </p>
                <p
                  className={
                    active
                      ? classes.planBlockDiscount
                      : `${classes.planBlockDiscount} ${classes.planBlockDiscountNotActive}`
                  }
                >
                  {`-${uiDisplayOptions?.discount_percents}%`}
                </p>
              </div>
              <div
                className={
                  active
                    ? classes.planBlockPrice
                    : `${classes.planBlockPrice} ${classes.planBlockPriceNotActive}`
                }
              >
                <span className={classes.dollarSign}>$</span>
                <span className={classes.integerFromPrice}>
                  {Math.floor(uiDisplayOptions?.daily_price_amount / 100)}
                </span>
                <div>
                  <span>{uiDisplayOptions?.daily_price_amount % 100}</span>
                  <p>per day</p>
                </div>
              </div>
            </div>
            {trialPeriodDuration !== 0 && (
              <p className={classes.planTrialDescrtiption}>
                You will <strong>only</strong> be charged{" "}
                <strong>
                  {trialPeriodPriceAmount / 100} for your {trialPeriodDuration}
                  -day
                </strong>{" "}
                trial
              </p>
            )}
          </div>
        )
      )}
      <button
        ref={scrollRef}
        className={classes.getPlanButton}
        onClick={handleOpenPayPal}
      >
        Get the plan
      </button>
      <p className={classes.descriptionPlanText}>
        Please note your subscription plan will be automatically renewed at the
        price of{" "}
        {subscriptionPlans.find((item) => item.active)?.uiDisplayOptions
          ?.daily_price_amount / 100}
        $ at the end of the chosen subscription term.
      </p>
    </>
  );
};

export default SelectPlan;
