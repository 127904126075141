import {
  ButtonWithAnswer,
  ButtonsGroup,
  QuestionsContainer,
} from "../../../shared";

const ANSWERS = ["Yes", "No"];

export const Wrinkles = ({ onNextStep }) => {
  return (
    <QuestionsContainer title="Do you have wrinkles?">
      <ButtonsGroup>
        {ANSWERS.map((answer) => (
          <ButtonWithAnswer key={answer} onClick={onNextStep}>
            {answer}
          </ButtonWithAnswer>
        ))}
      </ButtonsGroup>
    </QuestionsContainer>
  );
};
