import {
  ButtonWithAnswerEmoji,
  ButtonsGroup,
  QuestionsContainer,
} from "../../../shared";

const ANSWERS = [
  {
    emoji: "😞",
    title: "I don't use it at all",
  },
  {
    emoji: "😌",
    title: "1-2",
  },
  {
    emoji: "😊",
    title: "3-4",
  },
  {
    emoji: "🤩",
    title: "Decent amount of skincare product",
  },
];

export const SkincareProducts = ({ onNextStep }) => {
  return (
    <QuestionsContainer title="How many skincare products do you use daily?">
      <ButtonsGroup>
        {ANSWERS.map(({ emoji, title }) => (
          <ButtonWithAnswerEmoji key={title} emoji={emoji} onClick={onNextStep}>
            {title}
          </ButtonWithAnswerEmoji>
        ))}
      </ButtonsGroup>
    </QuestionsContainer>
  );
};
