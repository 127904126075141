import { Button } from "../../../shared";
import { Plan } from "./Plan";
import classes from "./styles.module.scss";

export const FirstChangesBreakdown = ({ onNextStep }) => {
  return (
    <div id="breakdown" className={classes.breakdown}>
      <h3>Get first changes in 4 weeks</h3>
      <div className={classes.content}>
        <div className={classes.chart}>
          <Plan />
        </div>
        <div className={classes.focus}>
          <h5>KEEP MOVING</h5>
          <p>
            If <strong>FACE YOGA</strong> is performed on a daily or weekly
            basis, results are unbelievable!
          </p>
        </div>
        <div className={classes.control}>
          <Button onClick={onNextStep}>Great</Button>
        </div>
      </div>
    </div>
  );
};
