import { Header } from "../configurableQuestions/header";
import { PendingIcon } from "./PendingIcon";
import classes from "./styles.module.scss";

export const PenddingReview = () => {
  return (
    <div className={classes.pendingReview}>
      <header className={classes.header}>
        <Header initial />
      </header>
      <div className={classes.container}>
        <div className={classes.content}>
          <div className={classes.icon}>
            <PendingIcon />
          </div>
          <h3>Pending review...</h3>
          <p>
            We would like to inform you that payment is pending for the product
            you have purchased. When the payment is processed, you will get an
            email notification.
          </p>
        </div>
      </div>
    </div>
  );
};
