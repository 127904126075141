// Libs
import * as yup from "yup";
import { useState } from "react";
import { useNavigate } from "react-router";
import { useForm } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import { yupResolver } from "@hookform/resolvers/yup";

// Assets
import community from "../../assets/community.jpg";
import mailbox from "../../assets/mailbox.jpg";

// API
import authService from "../../api/services/authService";

// Compontents
import SubmitButton from "../../common/SubmitButton";

// Utils
import getUserIP from "../../utils/getUserIP";

import classes from "./styles.module.scss";

const schema = yup
  .object()
  .shape({
    email: yup
      .string()
      .required("Email is required field!")
      .matches(
        /[a-zA-Z0-9]+@[a-zA-Z0-9]+.[a-zA-Z0-9]+/g,
        "Incorrect email. Please try again"
      ),
  })
  .required();

const JoinCommunites = () => {
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const {
    register,
    setError,
    formState: { errors },
    handleSubmit,
  } = useForm({
    resolver: yupResolver(schema),
  });

  const onSubmit = async ({ email }) => {
    setIsLoading(true);

    const ipAddress = await getUserIP();
    const { status, data } = await authService
      .emailOnlySignUp(email, ipAddress)
      .catch((error) => {
        setError("email", {
          type: "server",
          message: error?.response?.data?.message,
        });
        return error;
      });

    if (status === 200) {
      localStorage.setItem("FY-AuthToken", data.token);
      navigate("/paywall");
    }

    setIsLoading(false);
  };

  return (
    <div className={classes.page}>
      <p className={classes.title}>
        Join <span>our community</span> today
      </p>
      <p className={classes.subtitle}>Enter your email address below</p>
      <form
        onSubmit={handleSubmit(onSubmit)}
        className={classes.profileSettingsForm}
      >
        <input
          type="text"
          placeholder="Email"
          {...register("email")}
          className={errors.email && classes.errorInput}
        />
        <ErrorMessage
          errors={errors}
          name="email"
          render={({ message }) => (
            <p className={classes.errorMessage}>{message}</p>
          )}
        />
        <div className={classes.communityWrapper}>
          <img src={community} alt="community" />
          <p className={classes.communityInfo}>
            <strong>+3k</strong> people are already here
          </p>
        </div>
        <div className={classes.mailboxWrapper}>
          <img src={mailbox} alt="mailbox" />
        </div>
        <p className={classes.description}>
          By submitting you agree to our{" "}
          <span onClick={() => navigate("/privacy-policy ")}>
            Privacy Policy
          </span>
          . Your personal information is safe with us.
        </p>
        <SubmitButton
          type="submit"
          title="Submit"
          isLoading={isLoading}
          variant="greenButton"
        />
      </form>
    </div>
  );
};

export default JoinCommunites;
