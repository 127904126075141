// Libs
import { useNavigate } from "react-router";

// Components
import { Title } from "../../common/Title";
import { NextButton } from "../../common/NextButton";

// Assets
import carryJohnson from "../../assets/carryJohnson.png";
import checkMark from "../../assets/checkMark.svg";
import graphProcess from "../../assets/graphProcess.png";

import classes from "./styles.module.scss";

const PROGRAMM_INCLUDES = [
  {
    name: "Tension & swelling relieve",
  },
  {
    name: "Face uplift",
  },
  {
    name: "Jawline definition",
  },
  {
    name: "Discoloration fade ",
  },
];

const ObserveProcessPage = () => {
  const navigate = useNavigate();

  return (
    <div className={classes.page}>
      <Title>{"Observe the process and notice the results!"}</Title>
      <p className={classes.subTitle}>
        Each Face Yoga lesson and exercise is developed by professional
        beauticians
      </p>
      <div className={classes.cardWrapper}>
        <img src={carryJohnson} alt="Carry Johnson" />
        <div className={classes.cardInfoWrapper}>
          <p className={classes.cardName}>Carry Johnson</p>
          <p className={classes.cardJob}>
            Cosmetician with 5 years of experience
          </p>
        </div>
      </div>
      <Title>{"What’s the process?"}</Title>
      <div className={classes.graphWrapper}>
        <img src={graphProcess} alt="graph process" />
      </div>
      <Title>{"Your custom-made program includes"}</Title>
      <div className={classes.programListWrapper}>
        {PROGRAMM_INCLUDES.map(({ name }) => (
          <div key={name} className={classes.programListItem}>
            <div className={classes.programListItemIndex}>
              <img src={checkMark} alt="" />
            </div>
            <p className={classes.programListItemName}>{name}</p>
          </div>
        ))}
      </div>
      <NextButton
        title={"Continue"}
        onClick={() => {
          navigate("/finish-touches");
        }}
        disabled={true}
      />
    </div>
  );
};

export default ObserveProcessPage;
