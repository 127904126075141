// Libs
import { useNavigate } from "react-router";
import back from "../../assets/back.png";

import classes from "./styles.module.scss";

const TermsOfUse = () => {
  const navigate = useNavigate();

  return (
    <div className={classes.page}>
      <div className={classes.headerWrapper}>
        <button className={classes.headerButton} onClick={() => navigate(-1)}>
          <img src={back} alt="" />
        </button>
        <h1>Terms of service</h1>
      </div>
      <br />
      <p>Last updated December 23, 2022 </p>
      <br />
      <h2>
        Please read these Terms of Use carefully before using faceyoga.health
      </h2>
      <br />
      <p>
        These Terms of Use govern your use of faceyoga.health, a service
        available via the link{" "}
        <a
          href="https://faceyoga.health/"
          target="_blank"
          rel="noopener noreferrer"
        >
          https://faceyoga.health/
        </a>{" "}
        (the “Website”) referred to as the “Service”.
      </p>
      <br />
      <p>
        By using the Service on any computer, mobile phone, tablet or another
        device (collectively the “Device”), you as a user of the Service confirm
        that you have read, understand and agree to be bound by these Terms of
        Use and any other applicable law.
      </p>
      <br />
      <p>
        The Service and the content available therein are developed, operated
        and distributed: Straiton Limited (HE 371332), a company having its
        registered address at Alpha Tower, Office 11, 3021 further in the text,
        “we”, “us”, “our”.
      </p>
      <br />
      <p>
        We may change these Terms of Use at any time without notice, effective
        upon its posting on the Website and/or in the App (as applicable). Your
        continued use of the Service shall be considered your acceptance of the
        revised Terms of Use. If you do not agree to these Terms of Use, please
        do not use this Service.
      </p>
      <br />
      <h3>Scope of License</h3>
      <br />
      <p>
        Subject to these Terms of Use, we grant you non-exclusive,
        non-transferable, non-sublicensable, revocable limited license to use
        the Service solely for your personal, non-commercial purposes. This
        license does not allow you to use the Service on any Device that you do
        not own or rightfully control, and you may not distribute or make the
        Service available over a network where it could be used by multiple
        devices at the same time. You may not rent, lease, lend, sell,
        redistribute or sublicense the Service. You may not copy (except as
        expressly permitted by this license or terms of the relevant certified
        application store, from which you have downloaded the App), decompile,
        reverse engineer, disassemble, attempt to derive the source code of,
        modify or create derivative works of the Website or the App, any
        updates, or any part thereof (except as and only to the extent any
        foregoing restriction is prohibited by applicable law). Any attempt to
        do so is a violation of our rights as a licensor. If you breach this
        restriction, you may be subject to prosecution and damages.
      </p>
      <br />
      <h3>Intellectual Property</h3>
      <br />
      <p>
        All intellectual property on the Service, which includes materials
        protected by copyright, trademark, or patent laws, is proprietary either
        to us or to third parties. All trademarks, service marks and trade names
        are owned, registered and/or licensed by us. All content offered by the
        Service (except for personal information), including but not limited to
        text, software, scripts, code, designs, graphics, photos, sounds, music,
        videos, applications, interactive features and all other content (the
        “Content”) is our intellectual property; all rights reserved.
      </p>
      <br />
      <h3>Your User Account</h3>
      <br />
      <p>
        Access to certain features of the Service may be available to the
        registered users only. To create a user account, you must be at least 16
        years of age and are required to complete a registration form offered
        via the Service. User accounts for persons under the indicated age can
        be created by a parent or legal guardian.
      </p>
      <br />
      <p>
        By creating an account for use of the Service, you warrant that all the
        information provided by you for registration is true and accurate. You
        also agree not to misrepresent your identity by registering an account
        in the name of another person.
      </p>
      <br />
      <p>
        As a registered user of the Service, you must not sell or otherwise
        transfer your account credentials (i.e. your username and password) to
        any third party. You acknowledge that you are solely responsible for
        maintaining confidentiality and security of your user account
        credentials. We are not responsible for any losses arising from the
        unauthorized use of your account. If you suspect that your account has
        been compromised, please contact us at the applicable email address
        specified in section “Contact Us” below.
      </p>
      <br />
      <p>
        Your user account may be accessed by entering your credentials on any
        platform, across which the Service operates. You may terminate your user
        account at any time as described in the Termination section below.
      </p>
      <br />
      <p>
        We reserve the right to suspend or terminate your user account at any
        time without notice as provided by these Terms of Use.
      </p>
      <br />
      <h3>Privacy</h3>
      <br />
      <p>
        To use the Service, You may be asked to provide certain personal
        information. All the matters regarding Your personal information
        provision are governed by our{" "}
        <a
          href="https://faceyoga.health/privacy-policy"
          target="_blank"
          rel="noopener noreferrer"
        >
          Privacy Policy
        </a>{" "}
        and You are giving Your consent to the collection of such information by
        using the Service. Therefore, You should ensure that You have read the
        Privacy Policy provisions carefully.
      </p>
      <br />
      <h3>Links</h3>
      <br />
      <p>
        The Service may contain links to websites, Applications or other
        products or services operated by other companies (“Third Party
        Services”). We do not endorse, monitor or have any control over these
        Third Party Services, which have separate terms of use and privacy
        policies. We are not responsible for the content or policies of Third
        Party Services and You access such Third Party Services at Your own
        risk.
      </p>
      <br />
      <h3>Unauthorized Use</h3>
      <br />
      <p>
        You shall not in any way use the Service or submit to us or to the
        Service or to any user of the Service anything which in any respect:
      </p>
      <br />
      <ul>
        <li>
          is in breach of any law, statute, regulation or by-law of any
          applicable jurisdiction;
        </li>
        <li>is fraudulent, criminal or unlawful;</li>
        <li>is inaccurate or out-of-date;</li>
        <li>
          may be obscene, indecent, pornographic, vulgar, profane, racist,
          sexist, discriminatory, offensive, derogatory, harmful, harassing,
          threatening, embarrassing, malicious, abusive, hateful, menacing,
          defamatory, untrue or political;
        </li>
        <li>
          impersonates any other person or body or misrepresents a relationship
          with any person or body;
        </li>
        <li>
          may infringe or breach the copyright or any intellectual property
          rights (including without limitation copyright, trademark rights, and
          broadcasting rights) or privacy or other rights of us or any third
          party;
        </li>
        <li>may be adverse to our interests;</li>
        <li>
          is contrary to any specific rule or requirement that we stipulate on
          the Service in relation to a particular part of the Service or the
          Service in general;
        </li>
        <li>
          involves Your use, delivery or transmission of any viruses or anything
          likely intended to damage, detrimentally interfere with,
          surreptitiously intercept or expropriate any system, data or personal
          information.
        </li>
      </ul>
      <br />
      <h3>Fees and payments</h3>
      <br />
      <p>
        The Service offers subscription to the Content (as defined in section
        “Intellectual Property” of these Terms of Use) which may be purchased
        via the following means:
      </p>
      <br />
      <ul>
        <li>directly on the Website (the “Web Purchase”); or</li>
        <li>as an in-app purchase via the App (the “In-App Purchase”).</li>
      </ul>
      <br />
      <p>
        Depending on the purchasing option you choose, please refer to the
        appropriate section below to read the applicable purchase terms.
      </p>
      <br />
      <p>
        By making either the Web Purchase or the In-App Purchase, you are
        granted the right to access the features of the Service (Content,
        subscriptions) you have acquired as a result of such{" "}
      </p>
      <br />
      <p>
        purchase on any platform across which the Service operates. To access
        the purchase on the Website or in the App, please use your user account
        credentials.
      </p>
      <br />
      <h3>Web Purchase</h3>
      <br />
      <p>
        Through the Website you may purchase the access to the Content that is
        offered on a paid basis (the “Purchase”). The cost of the Purchase is
        provided within the Website.The Purchase within the Website can be made
        by using any payment method accepted by the Website.
      </p>
      <br />
      <p>
        By making Purchase and upon setting up a password you are granted access
        to the Content in a health-and-beauty sphere, related to facial workout
        and skincare.
      </p>
      <br />
      <p>
        You agree that Purchase is final, that we will not refund any
        transaction once it has been made and that the Purchase cannot be
        canceled. Notwithstanding the foregoing, you may be eligible to receive
        a refund within 30 (thirty) calendar days as of the Purchase date,
        provided that after following the Content recommendations you did not
        get visible results.
      </p>
      <br />
      <p>The Website may offer two types of Purchases:</p>
      <br />
      <ul>
        <li>
          one-time (lifetime purchase) Purchase - for the period and cost
          provided within the Website without any prolongations or renewals;
        </li>
        <li>
          subscription Purchase - for the period and cost provided within the
          Website with automatic prolongation/renewal for the same minimum term
          that has been initially selected until you or we cancel them.
        </li>
      </ul>
      <br />
      <p>
        Through the Website you may also purchase services placed on the
        external platform that are offered on a paid basis (the “Additional
        Purchase”). The cost of the Additional Purchase is provided within the
        Website.
      </p>
      <br />
      <p>
        BY PROCEEDING WITH PURCHASE, YOU CONFIRM AND AGREE THAT YOU ARE GOING TO
        CHARGED ONE TIME OR PERIODICALLY DEPENDING ON TYPE OF PURCHASE.
      </p>
      <br />
      <p>
        For avoidance of any doubts, by signing up for SUBSCRIPTION, you agree
        that your subscription WILL BE AUTOMATICALLY RENEWED. YOU MUST
        AFFIRMATIVELY CANCEL A SUBSCRIPTION TO AVOID BEING CHARGED AT LEAST 24
        HOURS BEFORE THE END OF THE CURRENT SUBSCRIPTION PERIOD. IN ORDER TO
        CANCEL A SUBSCRIPTION, SIMPLY CONTACT support@faceyoga.health.
      </p>
      <br />
      <p>
        We may offer a free trial subscription for the Service. Free trial
        provides you with access to the Service for a period of time, with
        details specified when you sign up for the offer. Unless you cancel
        before the end of the free trial, or unless otherwise stated, your
        access to the Service will automatically continue and you will be billed
        the applicable fees for the Service. We may send you a reminder when
        your free trial is about to end, but we do not guarantee any such
        notifications. It is ultimately your responsibility to know when the
        free trial will end. We reserve the right, in our absolute discretion,
        to modify or terminate any free trial offer, your access to the Service
        during the free trial, or any of these terms without notice and with no
        liability. We reserve the right to limit your ability to take advantage
        of multiple free trials.
      </p>
      <br />
      <p>
        The Service and your rights to use it expire at the end of the paid
        period of your subscription. If you do not pay the fees or charges due,
        we may make reasonable efforts to notify you and resolve the issue;
        however, we reserve the right to disable or terminate your access to the
        Service (and may do so without notice).
      </p>
      <br />
      <p>
        IN ADDITION TO PURCHASING SUBSCRIPTIONS, YOU MAY BE OFFERED ADD-ON ITEMS
        FOR AN ADDITIONAL FEE. This purchase is optional: your subscription is
        not conditional on such purchase. If you choose to purchase any add-on
        items, you will be immediately charged the relevant fee indicated on the
        purchase screen. In contrast to subscriptions, add-on items are one-off
        lifetime purchase that is final and cannot be canceled. Add-on items do
        not provide for a free trial.
      </p>
      <br />
      <p>
        In order to receive more information on a Refund, please contact Website
        customer service at support@faceyoga.health.
      </p>
      <br />
      <h3>In-App Purchase</h3>
      <br />
      <p>
        By purchasing a subscription to the Content via the App, you will pay
        the app store from which you have downloaded the App (the “App Store”)
        the applicable fees (and any related taxes) disclosed to you through the
        App as they become due.
      </p>
      <br />
      <p>
        SUBSCRIPTION AUTOMATICALLY RENEWS. YOU MUST AFFIRMATIVELY CANCEL A
        SUBSCRIPTION OR A FREE TRIAL TO AVOID BEING CHARGED IN YOUR APP STORE’S
        ACCOUNT SETTINGS AT LEAST 24 HOURS BEFORE THE END OF THE FREE TRIAL OR
        THE CURRENT SUBSCRIPTION PERIOD. IF YOU ARE UNSURE HOW TO CANCEL A
        SUBSCRIPTION OR A FREE TRIAL, PLEASE VISIT THE APPLE SUPPORT WEBSITE,
        GOOGLE PLAY HELP (OR ANY OTHER APP STORE’S SUPPORT PAGES). DELETING THE
        APP DOES NOT CANCEL YOUR SUBSCRIPTIONS AND FREE TRIALS.
      </p>
      <br />
      <p>
        To the maximum extent permitted by applicable laws, we may change
        subscription fees at any time. We will give you a reasonable notice of
        any such pricing changes by posting the new prices on or through the App
        and/or by sending you an email notification. If you do not wish to pay
        the new fees, you can cancel the applicable subscription prior to the
        change going into effect.
      </p>
      <br />
      <p>
        By signing up for certain subscriptions, you agree that your
        subscription may be automatically renewed. Unless you cancel your
        subscription, you authorize the App Store(s) to charge you for the
        renewal term. The period of auto-renewal will be the same as your
        initial subscription period unless otherwise disclosed to you through
        the App. The renewal rate will be no more than the rate for the
        immediately prior subscription period, excluding any promotional and
        discount pricing, unless we notify you of a rate change prior to your
        auto-renewal. You must cancel your subscription in accordance with the
        cancellation procedures disclosed to you for the particular
        subscription. We will not refund fees that may have accrued to your
        account and will not prorate fees for a canceled subscription.
      </p>
      <br />
      <p>
        We may offer a free trial subscription for the Service. Free trial
        provides you with access to the Service for a period of time, with
        details specified when you sign up for the offer. Unless you cancel
        before the end of the free trial, or unless otherwise stated, your
        access to the Service will automatically continue and you will be billed
        the applicable fees for the Service. We may send you a reminder when
        your free trial is about to end, but we do not guarantee any such
        notifications. It is ultimately your responsibility to know when the
        free trial will end. We reserve the right, in our absolute discretion,
        to modify or terminate any free trial offer, your access to the Service
        during the free trial, or any of these terms without notice and with no
        liability. We reserve the right to limit your ability to take advantage
        of multiple free trials.
      </p>
      <br />
      <p>
        The Service and your rights to use it expire at the end of the paid
        period of your subscription. If you do not pay the fees or charges due,
        we may make reasonable efforts to notify you and resolve the issue;
        however, we reserve the right to disable or terminate your access to the
        Service (and may do so without notice).
      </p>
      <br />
      <p>
        IN ADDITION TO PURCHASING SUBSCRIPTIONS, YOU MAY BE OFFERED ADD-ON ITEMS
        FOR AN ADDITIONAL FEE. This purchase is optional: your subscription is
        not conditional on such purchase. If you choose to purchase any add-on
        items, you will be immediately charged the relevant fee indicated on the
        purchase screen. In contrast to subscriptions, add-on items are one-off
        lifetime purchases that are final and cannot be canceled. Add-on items
        do not provide for a free trial.
      </p>
      <br />
      <p>
        You authorize the App Store(s) to charge the applicable fees to the
        payment card that you submit.
      </p>
      <br />
      <p>
        Subscriptions as well as add-on items purchased via an App Store are
        subject to such App Store's refund policies. This means we cannot grant
        refunds. You will have to contact App Store support.
      </p>
      <br />
      <h3>Disclaimer</h3>
      <br />
      <p>
        Your use of any aspect of the Service is at Your own risk. You must
        consult with certified healthcare advisers and physicians and make Your
        medical decisions based on their advice. We cannot and do not accept any
        liability in respect of any activities that You may undertake through
        the Service use.
      </p>
      <br />
      <p>
        We make no representations or warranties whatsoever in respect of the
        Service. Information regarding health, medical advice and otherwise may
        be provided by third parties, including other users of the Service. We
        cannot accept any liability whatsoever in respect of any content which
        is provided by third parties and/or any other users of the Service. Any
        actions You take based on content, notifications and otherwise provided
        by the Service are taken at Your sole risk and we will not accept any
        liability in respect thereof. You should always check any information
        provided through the Service to ensure its accuracy. To the maximum
        extent permitted by applicable law, the Service is provided on an “as
        is” and “as available” basis. We make no representations or warranties
        of any kind, express or implied, as to the operation of the Service or
        any information, content, materials or products included or referenced
        therein. To the full extent permissible by applicable law, we disclaim
        all warranties, express or implied, including, but not limited to,
        implied warranties of merchantability, non-infringement of third
        parties’ rights and fitness for a particular purpose. You acknowledge
        that Your use of the Service is at Your sole risk. We disclaim any
        implied or statutory warranties (i) regarding the security, accuracy,
        reliability, timeliness and performance of the Service; or (ii) that the
        Service will be error-free or that any errors will be corrected; or
        (iii) regarding the performance of or accuracy, quality, currency,
        completeness or usefulness of any information provided by the Service.
        We do not warrant that any description provided through the Service
        regarding healthcare or otherwise is accurate, complete, reliable,
        current, safe or error-free. No communication, information or advice
        given by us or any representative of ours, whether written or oral,
        shall create any warranty. If You choose to rely on such information,
        You do so solely at Your own risk. Some states or jurisdictions do not
        allow the exclusion of certain warranties. Accordingly, some of the
        above exclusions may not apply to You.
      </p>
      <br />
      <p>
        The Service may not be available in all languages or in all countries,
        and we make no representation that the functionality of the Service
        would be appropriate, accurate or available for use in any particular
        location. The Service availability and pricing are subject to change.
      </p>
      <br />
      <p>This disclaimer constitutes an essential part of this Terms of Use.</p>
      <br />
      <h3>Limitation of liability</h3>
      <br />
      <p>
        To the maximum extent permitted by applicable law, under no
        circumstances and under no legal or equitable theory, whether in tort,
        contract, strict liability or otherwise, shall we, our affiliates, or
        any of our or their employees, directors, officers, agents, vendors or
        suppliers be liable to You or to any third party for any personal
        injury, including death, or for any indirect, special, incidental or
        consequential losses or damages of any nature arising out of or in
        connection with the use of or inability to use the Service, including,
        without limitation, damages for lost profits, loss of goodwill, loss of
        data, work stoppage, accuracy of results, computer or device failure or
        malfunction, even if a representative of ours has been advised of or
        should have known of the possibility of such damages. In no event will
        we be liable for any damages in excess of fifty US dollars ($50).
      </p>
      <br />
      <p>
        Some jurisdictions do not allow the exclusion of certain warranties or
        the limitation or exclusion of liability for certain damages.
        Accordingly, some of the above limitations and disclaimers may not apply
        to You. To the extent that we may not, as a matter of applicable law,
        disclaim any implied warranty or limit liabilities, the scope and
        duration of such warranty and the extent of our liability will be the
        minimum permitted under such applicable law.
      </p>
      <br />
      <p>
        Any claims arising in connection with Your use of the Service must be
        brought within one (1) year of the date of the event giving rise to such
        action occurred. Remedies under this Terms of Use are exclusive and are
        limited to those expressly provided for in this Terms of Use, even if
        the applicable remedy under this Terms of Use fails of its essential
        purpose.
      </p>
      <br />
      <h3>Indemnity</h3>
      <br />
      <p>
        You agree to defend, indemnify, and hold us harmless including our
        officers, directors, employees, agents, subcontractors, licensors and
        suppliers, any of our affiliated companies or organizations, and any
        successors, assigns or licensees, from and against any claims, actions
        or demands, damages, losses, liabilities, judgments, settlements, costs
        or expenses (including attorneys’ fees and costs) arising directly or
        indirectly from or relating to a) the breach of this Terms of Use by You
        or anyone using Your computer, mobile device, password or login
        information; b) any claim, loss or damage experienced from Your use or
        attempted use of (or inability to use) the Service; c) Your violation of
        any law or regulation; or d) any other matter for which You are
        responsible under this Terms of Use or under any applicable law. You
        agree that Your use of the Service shall be in compliance with all
        applicable laws, regulations, and guidelines.
      </p>
      <br />
      <p>
        We reserve the right to assume the exclusive defense and control of any
        demand, claim or action arising hereunder or in connection with the App
        and all negotiations for settlement or compromise. You agree to fully
        cooperate with us in the defense of any such demand, claim, action,
        settlement or compromise negotiations, as requested by us.
      </p>
      <br />
      <h3>Termination</h3>
      <br />
      <p>
        These Terms of Use are effective until terminated by either you or us.
      </p>
      <br />
      <p>
        You may terminate these Terms of Use at any time, provided that you
        discontinue any further use of the Service. You may also terminate your
        user account by using the appropriate deletion functions, if available
        through the Service.
      </p>
      <br />
      <p>
        If you violate these Terms of Use, we reserve the right to terminate
        your use or access to the Service, including by means of terminating
        your account.
      </p>
      <br />
      <p>
        We, however, may, in our sole discretion, terminate these Terms of Use,
        your access to any part or all of the Service, or your account, at any
        time and for any reason, without penalty or liability to you or any
        third party. In the event of your breach of these Terms of Use, these
        actions are in addition to and not in lieu or as limitation of any other
        right or remedy that may be available to us.
      </p>
      <br />
      <p>
        Upon any termination of the Terms of Use by either you or us, you must
        promptly uninstall the App on all of your Devices and destroy all
        materials downloaded or otherwise obtained from the Service, all
        documentation, and all copies of such materials and documentation. The
        following provisions survive the expiration or termination of these
        Terms of Use for any reason whatsoever: Intellectual Property,
        Disclaimer, Limitations of Liability, Indemnity, Choice of Law and
        Dispute Resolution, Entire Agreement and Severability.
      </p>
      <br />
      <h3>Choice of Law and Dispute Resolution</h3>
      <br />
      <p>
        These Terms of Use and any non-contractual obligations arising out of or
        in connection with them shall be governed by, and construed in
        accordance with the laws of the Republic of Cyprus. Any dispute,
        controversy or claim arising out of or in connection with these Terms of
        Use, or the breach, termination or invalidity thereof, shall be finally
        settled by the laws of the Republic of Cyprus.
      </p>
      <br />
      <h3>Entire Agreement</h3>
      <br />
      <p>
        These Terms of Use,{" "}
        <a
          href="https://faceyoga.health/privacy-policy"
          target="_blank"
          rel="noopener noreferrer"
        >
          Privacy Policy
        </a>
        ,{" "}
        <a
          href="https://faceyoga.health/subscription-policy"
          target="_blank"
          rel="noopener noreferrer"
        >
          Web Purchase Refund Policy
        </a>{" "}
        constitute the entire agreement between you and us pertaining to the
        subject matter hereof (the “Agreement”). Anything contained in or
        delivered through the Service that is inconsistent with or conflicts
        with the terms of this Agreement is superseded by the terms of this
        Agreement. These Terms of Use may not be modified, in whole or in part,
        except as described elsewhere in these Terms of Use.
      </p>
      <br />
      <h3>Severability</h3>
      <br />
      <p>
        If any of the provisions of these Terms of Use are held to be not
        enforceable by a court or other tribunal of competent jurisdiction, then
        such provisions shall be amended, limited or eliminated to the minimum
        extent necessary so that these Terms of Use shall otherwise remain in
        full force and effect.
      </p>
      <br />
      <h3>Assignability</h3>
      <br />
      <p>
        You may not assign or transfer these Terms of Use, by operation of law
        or otherwise, without our prior written and explicit consent.
      </p>
      <br />
      <p>
        You agree that these{" "}
        <a
          href="https://faceyoga.health/terms-of-use"
          target="_blank"
          rel="noopener noreferrer"
        >
          Terms of Use
        </a>{" "}
        and/or the agreement between You and us in general may be assigned by
        us, in our sole discretion to any third party.
      </p>
      <br />
      <h3>Contact Us</h3>
      <br />
      <p>
        All notices to You relating to these Terms of Use shall be notified to
        You either via the Application or via e-mail that You provided to us.
      </p>
      <br />
      <p>
        Please submit any notices to us relating to these Terms of Use via:
        support@faceyoga.health
      </p>
    </div>
  );
};

export default TermsOfUse;
