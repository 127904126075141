import { InitialsMap } from "./data/initialsMap";
import initialStepConfig from "../../../initialStepConfig.json";
import { Suspense } from "react";
import { Header } from "../header";
import classes from "./styles.module.scss";

export const InitialStep = () => {
  return (
    <Suspense>
      <div className={classes.question}>
        <header className={classes.header}>
          <Header initial />
        </header>
        <div className={classes.content}>
          {InitialsMap[initialStepConfig.initialStep]}
        </div>
      </div>
    </Suspense>
  );
};
