import { Rate } from "../configurableQuestions/breakdowns/components/results/Rate";
import { Header } from "../configurableQuestions/header";
import LoadingBars from "./LoadingBars";
import classes from "./styles.module.scss";

export const Analyze = () => {
  return (
    <div className={classes.questions}>
      <header className={classes.header}>
        <Header initial />
      </header>
      <div className={classes.content}>
        <div className={classes.loading}>
          <h3>Analyzing your answers...</h3>
          <LoadingBars />
        </div>
        <div className={classes.footer}>
          <h4 className={classes.trusted}>Trusted by over 141,562 clients</h4>
          <div className={classes.feedback}>
            <Rate />
            <h5>
              This was great! It not only relaxed my face, but my body and mind
              felt it too!
            </h5>
            <p>Monika Less</p>
          </div>
        </div>
      </div>
    </div>
  );
};
