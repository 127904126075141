const getUserIP = async () => {
  const IP = await fetch("https://api.ipify.org/")
    .then((resp) => resp.text())
    .catch((error) => {
      console.log(error);
    });
  return IP;
};

export default getUserIP;
