import {
  ButtonWithAnswerEmoji,
  ButtonsGroup,
  QuestionsContainer,
} from "../../../shared";

const ANSWERS = [
  {
    emoji: "🍲",
    title: "Around 3 meals a day",
  },
  {
    emoji: "🍽️",
    title: "Less than 3 meals a day",
  },
  {
    emoji: "❓",
    title: "It's different from day-to-day",
  },
];

export const Food = ({ onNextStep }) => {
  return (
    <QuestionsContainer title="How many times do you eat per day?">
      <ButtonsGroup>
        {ANSWERS.map(({ emoji, title }) => (
          <ButtonWithAnswerEmoji key={title} emoji={emoji} onClick={onNextStep}>
            {title}
          </ButtonWithAnswerEmoji>
        ))}
      </ButtonsGroup>
    </QuestionsContainer>
  );
};
