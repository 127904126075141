import { createAsyncThunk } from "@reduxjs/toolkit";
import { getFirebaseApp } from "../../../utils/getFirebaseApp";
import {
  getRemoteConfig,
  fetchAndActivate,
  getString,
} from "firebase/remote-config";
import { getCookie, setCookie } from "../../../utils/cookies";

export const getSequenceThunk = createAsyncThunk("steps/getSteps", async () => {
  let currentSequence = getCookie("flow_id");
  if (!currentSequence) {
    const app = getFirebaseApp();
    const config = getRemoteConfig(app);
    config.settings.minimumFetchIntervalMillis = 10000;
    await fetchAndActivate(config);
    currentSequence = getString(config, "flow_id");
    console.log(currentSequence);

    setCookie("flow_id", currentSequence, 30);
  }
  try {
    const data = require(`../../../qustionsSequnces/${currentSequence}.json`);
    return data;
  } catch (error) {
    console.log(error);
    window.location.href = "/something-went-wrong";
  }
});
