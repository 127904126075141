import {
  ButtonWithAnswer,
  ButtonsGroup,
  QuestionsContainer,
} from "../../../shared";

const ANSWERS = [
  "No",
  "Never",
  "Occasionally",
  "Only in my T-zone (forehead, nose, chin)",
  "Yes",
  "All over my face",
];

export const OilyShine = ({ onNextStep }) => {
  return (
    <QuestionsContainer title="Do you notice oily shine on your face?">
      <ButtonsGroup>
        {ANSWERS.map((answer) => (
          <ButtonWithAnswer key={answer} onClick={onNextStep}>
            {answer}
          </ButtonWithAnswer>
        ))}
      </ButtonsGroup>
    </QuestionsContainer>
  );
};
