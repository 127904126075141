// Libs
import { useEffect, useState } from "react";

// Assets
import loader from "../../assets/loader.svg";
import checkMark from "../../assets/checkMark.svg";

import classes from "./styles.module.scss";

const AnalysisData = ({ interval, text }) => {
  const [isReady, setIsReady] = useState(false);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setIsReady(true);
    }, interval);
    return () => clearTimeout(timeoutId);
  }, [interval]);

  return (
    <div className={classes.analysisItem}>
      {isReady ? (
        <div className={classes.analysisDone}>
          <img src={checkMark} alt="" />
        </div>
      ) : (
        <div className={classes.loaderWrapper}>
          <img src={loader} alt="" />
        </div>
      )}
      <p className={classes.analysisText}>{text}</p>
    </div>
  );
};

export default AnalysisData;
