import { createSlice } from "@reduxjs/toolkit";
import { getSequenceThunk } from "./thunks/stepsThunk";

const initialState = {
  steps: [],
};

const steps = createSlice({
  name: "steps",
  initialState,
  reducers: {
    setNextStep(state) {
      const currentStepIndex = state.steps.findIndex(
        (item) => item === state.currentStep
      );
      state.currentStep = state.steps[currentStepIndex + 1];
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getSequenceThunk.fulfilled, (state, { payload }) => {
      if (payload) {
        state.steps = payload.data;
      }
    });
  },
});

export const reducer = steps.reducer;

export const { setNextStep } = steps.actions;
