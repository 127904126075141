import { useDispatch, useSelector } from "react-redux";
import { openPayPal } from "../../../../redux/slices/subscriptionSlice";

import classes from "./../../styles.module.scss";

const TicketOffer = () => {
  const dispatch = useDispatch();

  const subscriptionPlan = useSelector((state) => state.subscription.plans[1]);

  const handleOpenPayPal = () => dispatch(openPayPal(subscriptionPlan.id));

  return (
    subscriptionPlan && (
      <div className={classes.ticketContainer}>
        <p className={classes.ticketTitle}>Your special price</p>
        <p className={classes.ticketPlan}>
          {subscriptionPlan.billingPeriodDuration / 100}-months plan
        </p>
        <p className={classes.ticketPrice}>
          $<span>0.33</span>
          per day
        </p>
        <p className={classes.ticketSave}>Save - $ 1787</p>
        <button className={classes.ticketButton} onClick={handleOpenPayPal}>
          Claim this offer
        </button>
      </div>
    )
  );
};

export default TicketOffer;
