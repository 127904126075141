import {
  ButtonWithAnswerEmoji,
  ButtonsGroup,
  QuestionsContainer,
} from "../../../shared";

const ANSWERS = [
  {
    emoji: "🥰",
    title: "Seldom",
  },
  {
    emoji: "😊",
    title: "Sometimes",
  },
  {
    emoji: "😢",
    title: "Quite often",
  },
  {
    emoji: "😭",
    title: "Every day",
  },
];

export const Acne = ({ onNextStep }) => {
  return (
    <QuestionsContainer title="How many times over the past week have you had acne?">
      <ButtonsGroup>
        {ANSWERS.map(({ emoji, title }) => (
          <ButtonWithAnswerEmoji emoji={emoji} onClick={onNextStep}>
            {title}
          </ButtonWithAnswerEmoji>
        ))}
      </ButtonsGroup>
    </QuestionsContainer>
  );
};
