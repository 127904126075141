import {
  ButtonWithAnswerEmoji,
  ButtonsGroup,
  QuestionsContainer,
} from "../../../shared";

const ANSWERS = [
  {
    emoji: "🥰",
    title: "I don't have a particular skincare routine",
  },
  {
    emoji: "🤔",
    title: "I have a few skincare habits",
  },
  {
    emoji: "😢",
    title: "I take a proper care of my skin",
  },
];

export const SkincareRoutine = ({ onNextStep }) => {
  return (
    <QuestionsContainer title="Do you have daily skin care routine?">
      <ButtonsGroup>
        {ANSWERS.map(({ emoji, title }) => (
          <ButtonWithAnswerEmoji key={title} emoji={emoji} onClick={onNextStep}>
            {title}
          </ButtonWithAnswerEmoji>
        ))}
      </ButtonsGroup>
    </QuestionsContainer>
  );
};
