// Libs
import { useRef } from "react";

// Components
import { SelectPlan } from "./components/selectPlan";
import CountTimer from "./components/countTimer/CountTimer";
import FaceYogaAdvantages from "./components/faceYogaAdvantages/FaceYogaAdvantages";
import DropDown from "./components/dropDown/DropDown";
import Issue from "./components/issue/Issue";
import TicketOffer from "./components/ticketOffer/TicketOffer";

// Data
import {
  ADVANTAGES_DATA,
  EXPECT_BLOCK_DATA,
  QUESTIONS_DATA,
  REVIEWS_DATA,
} from "./data";

// Assets
import selectPlanWomen from "../../assets/selectPlanWomen.jpg";
import beforeAfter from "../../assets/beforeAfter.jpg";
import expectSkin from "../../assets/expectSkin.jpg";
import graph from "../../assets/graph.png";

import classes from "./styles.module.scss";

const PaywallPage = () => {
  const scrollRef = useRef(null);

  const executeScroll = () =>
    scrollRef.current.scrollIntoView({ behavior: "smooth", block: "center" });

  return (
    <div className={classes.container}>
      <div className={classes.specialOfferWrapper}>
        <CountTimer minutes={5} executeScroll={executeScroll} />
        <button className={classes.getProgramButton} onClick={executeScroll}>
          Get my program
        </button>
      </div>
      <div className={classes.personalizedPlanWrapper}>
        <p className={classes.personalizedPlanTitle}>
          Your personalized plan is ready
        </p>
        <p className={classes.personalizedPlanSubtitle}>
          <b>93%</b> of Face Yoga users detect positive changes within the first
          week of exercising
        </p>
      </div>
      <img
        className={classes.selectPlanImage}
        src={selectPlanWomen}
        alt="select plan"
      />
      <div className={classes.selectPlanContainer}>
        <p className={classes.selectPlanTitle}>Select Your Plan</p>
        <div className={classes.soldOutBlock}>
          <p className={classes.soldOutTitle}>Sold out</p>
          <div className={classes.soldOutContentWrapper}>
            <p className={classes.soldOutDate}>7 days</p>
            <div className={classes.soldOutPrice}>
              <span className={classes.dollarSign}>$</span>
              <span className={classes.integerFromPrice}>2</span>
              <div>
                <span>14</span>
                <p>per day</p>
              </div>
            </div>
          </div>
        </div>
        <SelectPlan scrollRef={scrollRef} />
        <div className={classes.archiveResultsContainer}>
          <p className={classes.archiveResultsTitle}>
            Achieve results like that!
          </p>
          <p className={classes.archiveResultsSubtitle}>
            Look through these incredible before-after progress pictures from
            our users.
          </p>
          <div className={classes.beforeAfterWrapper}>
            <div className={classes.tabWrapper}>
              <p className={classes.tabText}>Before</p>
              <p className={classes.tabText}>After</p>
            </div>
            <img src={beforeAfter} alt="beforeAfter" />
            <p className={classes.beforeAfterName}>Kathy, 31</p>
            <p className={classes.beforeAfterComment}>
              Easy and effective exercises. My face looks more bright and
              well-rested!
            </p>
          </div>
        </div>
        <div className={classes.advantageBlockContainer}>
          <p className={classes.advantageTitle}>
            Only <span>$0.33 per day </span> with Face Yoga will get you:
          </p>
          <FaceYogaAdvantages data={ADVANTAGES_DATA} />
        </div>
        <TicketOffer />
        <div className={classes.expectContainer}>
          <p className={classes.expectTitle}>
            <span>96% of our users</span> started feeling and <br></br> looking
            more <br></br>rejuvenated
          </p>
          <p className={classes.expectSubtitle}>
            This program is created for you and is{" "}
            <span>developed on your answers</span>
          </p>
          <p className={classes.expectDescription}>
            Here’s something to expect after <b>one week:</b>
          </p>
          <img src={expectSkin} alt="expect skin" />
          <ul className={classes.expectList}>
            {EXPECT_BLOCK_DATA.map(({ name }, index) => (
              <li key={name + index} className={classes.expectListItem}>
                <div className={classes.expectListItemIndex}>{index + 1}</div>
                <p className={classes.expectListItemDescription}>{name}</p>
              </li>
            ))}
          </ul>
        </div>
        <div className={classes.graphContainer}>
          <p className={classes.graphTitle}>
            Look <span>5 years younger</span> in a couple of <span>months</span>
          </p>
          <img src={graph} alt="graph" />
        </div>
        <div className={classes.benefitsContainer}>
          <p className={classes.benefitsTitle}>
            Proven benefits of face exercising:
          </p>
          <FaceYogaAdvantages data={EXPECT_BLOCK_DATA} />
        </div>
        <div className={classes.reviewsContainer}>
          <p className={classes.reviewsTitle}>
            Join <span>thousands</span> of our elated users
          </p>
          <div className={classes.reviewsWrapper}>
            {REVIEWS_DATA.map(({ avatar, name, comment, stars, starImage }) => (
              <div key={name} className={classes.reviewItem}>
                <div className={classes.reviewHeader}>
                  <img src={avatar} alt={name} />
                  <div className={classes.reviewReitingWrapper}>
                    <p className={classes.reviewName}>{name}</p>
                    <div className={classes.starsWrapper}>
                      {stars.map((_, index) => (
                        <img src={starImage} alt="star" key={index} />
                      ))}
                    </div>
                  </div>
                </div>
                <p className={classes.reviewComment}>{comment}</p>
              </div>
            ))}
          </div>
        </div>
        <div className={classes.questionsContainer}>
          <div className={classes.questionsTitle}>Any questions?</div>
          <div className={classes.questionsSubtitle}>
            We’re here to assist you!
          </div>
          {QUESTIONS_DATA.map((data) => (
            <DropDown key={data.title} {...data} />
          ))}
        </div>
        <div className={classes.guaranteedContainer}>
          <p className={classes.guaranteedTitle}>
            Guaranteed results in 60 days
          </p>
          <p className={classes.guaranteedDescription}>
            The team at Face Yoga is absolutely sure of efficient and proven
            results. If you are ready to achieve your goals of getting a dream
            face Face Yoga is for you!
          </p>
        </div>
        <Issue />
      </div>
    </div>
  );
};

export default PaywallPage;
