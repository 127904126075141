import { useNavigate, useSearchParams } from "react-router-dom";
import { QuestionsMap } from "./data/questionsMap";
import { useSelector } from "react-redux";
import { useEffect } from "react";
import { ProgressBar } from "../../../common/ProgressBar";
import classes from "./styles.module.scss";
import { Header } from "../header";

export const QuestionsTest = () => {
  const steps = useSelector((state) => state.steps.steps);
  const [params, setParams] = useSearchParams();
  const navigate = useNavigate();
  const currentQuestion = params.get("q");
  const currentStepIndex = steps.findIndex((item) => item === currentQuestion);

  const nextStepHandler = () => {
    if (currentStepIndex === steps.length - 1) {
      navigate("/analyze");
      return;
    }
    const stepToParam = steps[currentStepIndex + 1];
    setParams({ q: stepToParam });
  };

  useEffect(() => {
    const breakdownElement = document.getElementById("breakdown");
    const progressBar = document.querySelector("#progressbar");

    if (breakdownElement) {
      progressBar.style.opacity = "0";
    } else {
      progressBar.style.opacity = "1";
    }
  }, [params]);

  const Component = QuestionsMap[currentQuestion];

  return (
    <div className={classes.question}>
      <header className={classes.header}>
        <Header onSkip={nextStepHandler} />
      </header>
      <div className={classes.progressBar} id="progressbar">
        <ProgressBar
          readyStylePersents={((currentStepIndex + 1) / steps.length) * 100}
        />
      </div>
      <div className={classes.content}>
        <Component onNextStep={nextStepHandler} />
      </div>
    </div>
  );
};
