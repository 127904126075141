import { useState } from "react";

export const useChecks = () => {
  const [checkedCards, setCheckedCards] = useState([]);
  const isChecked = (card) => checkedCards.includes(card);
  const checkHandler = (card) => () => {
    if (isChecked(card)) {
      setCheckedCards(
        checkedCards.filter((checkedCard) => checkedCard !== card)
      );
    } else {
      setCheckedCards((prevState) => [...prevState, card]);
    }
  };

  return { checkHandler, isChecked, checkedCards };
};
