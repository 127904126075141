import {
  ButtonWithAnswerEmoji,
  ButtonsGroup,
  QuestionsContainer,
} from "../../../shared";

import glass from "../../../../../assets/img/png/glass.png";
import bottle from "../../../../../assets/img/png/bottle.png";
import barrel from "../../../../../assets/img/png/barrel.png";

const ANSWERS = [
  {
    emoji: <img src={glass} alt="glass" />,
    title: "I drink less than 1.5 liters per day",
  },
  {
    emoji: <img src={bottle} alt="bottle" />,
    title: "I drink 1.5-2 liters per day",
  },
  {
    emoji: <img src={barrel} alt="barrel" />,
    title: "I drink more 2 liters per day",
  },
  {
    emoji: "😢",
    title: "I don't know",
  },
];

export const Water = ({ onNextStep }) => {
  return (
    <QuestionsContainer title="How much water do you drink per day?">
      <ButtonsGroup>
        {ANSWERS.map(({ emoji, title }) => (
          <ButtonWithAnswerEmoji emoji={emoji} onClick={onNextStep}>
            {title}
          </ButtonWithAnswerEmoji>
        ))}
      </ButtonsGroup>
    </QuestionsContainer>
  );
};
