import { QuestionCheckbox } from "./checkbox";
import classes from "./styles.module.scss";

export const CardWithCheckbox = ({ checked, title, onClick }) => {
  return (
    <div
      onClick={onClick}
      className={
        checked
          ? `${classes.cardWithCheckbox} ${classes.checked}`
          : `${classes.cardWithCheckbox}`
      }
    >
      <div className={classes.content}>
        {title}
        <div className={classes.checkbox}>
          <QuestionCheckbox checked={checked} />
        </div>
      </div>
    </div>
  );
};
