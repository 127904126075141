import classes from "./styles.module.scss";
import { useMemo, useState } from "react";
import arrow from "./../../../../assets/arrow.svg";

const DropDown = ({ title, description }) => {
  const [toogleOpen, setToogleOpen] = useState(true);

  return useMemo(
    () => (
      <div
        className={classes.dropDownItem}
        onClick={() => {
          setToogleOpen((bool) => !bool);
        }}
      >
        <div className={classes.dropDownHeader}>
          <p className={classes.dropDownTitle}>{title}</p>
          <img
            src={arrow}
            alt="arrow"
            className={toogleOpen ? classes.arrowClose : classes.arrowOpen}
          />
        </div>
        <div
          className={
            toogleOpen
              ? classes.dropDownDescription
              : classes.dropDownDescriptionOpen
          }
        >
          {description}
        </div>
      </div>
    ),
    [description, title, toogleOpen]
  );
};

export default DropDown;
