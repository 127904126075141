import { useForm } from "react-hook-form";
import { useState } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { ErrorMessage } from "@hookform/error-message";
import Modal from "../../../../common/Modal";

import classes from "./../../styles.module.scss";
import SubmitButton from "../../../../common/SubmitButton";
import supportService from "../../../../api/services/supportService";

const schema = yup
  .object()
  .shape({
    email: yup
      .string()
      .required("E-mail is required!")
      .matches(
        /[a-zA-Z0-9]+@[a-zA-Z0-9]+.[a-zA-Z0-9]+/g,
        "Incorrect email. Please try again"
      ),
    text: yup.string().required("Message is required field"),
  })
  .required();

const SupportModal = ({ toogleModal }) => {
  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm({
    resolver: yupResolver(schema),
  });

  const [isLoading, setIsLoading] = useState(false);

  const onSubmit = async ({ email, text }) => {
    setIsLoading(true);

    await supportService.supportRequest(email, text);

    setIsLoading(false);
  };

  return (
    <Modal toogleModal={toogleModal}>
      <p className={classes.modalTitle}>Support</p>
      <form onSubmit={handleSubmit(onSubmit)} className={classes.modalForm}>
        <input
          placeholder="Email"
          className={
            errors.email
              ? `${classes.supportModalInput} ${classes.errorInput}`
              : classes.supportModalInput
          }
          {...register("email")}
        />
        <ErrorMessage
          errors={errors}
          name="email"
          render={({ message }) => (
            <p className={classes.errorMessage}>{message}</p>
          )}
        />
        <textarea
          placeholder="Leave your request here"
          className={
            errors.text
              ? `${classes.supportModalTextArea} ${classes.errorInput}`
              : classes.supportModalTextArea
          }
          {...register("text")}
        />
        <ErrorMessage
          errors={errors}
          name="text"
          render={({ message }) => (
            <p className={classes.errorMessage}>{message}</p>
          )}
        />
        <SubmitButton
          type="submit"
          title="send"
          isLoading={isLoading}
          variant="blackButton"
        />
      </form>
    </Modal>
  );
};

export default SupportModal;
