import classes from "./styles.module.scss";

export const ProgressBar = ({ readyStylePersents }) => {
  return (
    <div className={classes.wrapper}>
      <div className={classes.progress_bar}>
        <span
          className={classes.progress_bar_fill}
          style={{ width: `${readyStylePersents}%` }}
        ></span>
      </div>
    </div>
  );
};
