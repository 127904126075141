import { Header } from "../configurableQuestions/header";
import { QuestionCheckbox } from "../configurableQuestions/shared/components/cardWithCheckbox/checkbox";
import { Chart } from "./components/svg/Chart";
import { Garant } from "./components/svg";
import classes from "./styles.module.scss";
import { Button } from "../configurableQuestions/shared";
import { Images } from "../../utils/images";
import Countdown from "react-countdown";
import { PlanItem } from "./components/planItem";
import { useFetchPlans } from "./hooks/useFetchPlans";
import "react-spring-bottom-sheet/dist/style.css";
import { Loader } from "../../common/loader";
import "react-spring-bottom-sheet/dist/style.css";
import { BottomSheet } from "react-spring-bottom-sheet";

const BENEFITS = [
  "Prevent aging and look younger",
  "Your personal face fat remover",
  "Support to sustain your success",
  "Better health",
  "Motivating personal insights",
  "A happier and more confident you",
];

export const Plan = () => {
  const {
    plans,
    activePlan,
    setActivePlan,
    handleSubmitPlan,
    setOpen,
    open,
    url,
    handleLoad,
    loading,
    onDismiss,
  } = useFetchPlans();

  const renderer = ({ minutes, seconds, completed }) => {
    if (completed) {
      return <>Time is up!</>;
    } else {
      return (
        <span>
          {minutes}:{seconds === 0 ? `0${seconds}` : seconds}
        </span>
      );
    }
  };

  const { users } = Images;
  return (
    <>
      <BottomSheet
        onDismiss={onDismiss}
        snapPoints={({ _, maxHeight }) => [maxHeight / 1.3, maxHeight / 1.3]}
        open={open}
      >
        {loading && (
          <div className={classes.loaderContainer}>
            <Loader />
          </div>
        )}
        <div
          className={
            loading
              ? `${classes.hide} ${classes.paypage}`
              : `${classes.paypage}`
          }
        >
          <iframe
            onLoad={() => {
              handleLoad();
            }}
            height="600px"
            width="100%"
            title="paywall"
            src={url}
          />
        </div>
      </BottomSheet>

      <div className={classes.questions}>
        <header className={classes.header}>
          <Header initial />
        </header>
        <div className={classes.content}>
          <div className={classes.personalized}>
            <div className={classes.gradFrame}>
              <div className={classes.personalizedContent}>
                <h3>
                  Your <span>personalized</span> 3-month plan
                </h3>
                <p>
                  We've reserved your personalized plan for 15 minutes. Don't
                  miss out!
                </p>
                <h4>
                  Time remaining{" "}
                  <span>
                    <Countdown date={Date.now() + 900000} renderer={renderer} />
                  </span>
                </h4>
              </div>
            </div>
          </div>
          <div className={classes.less}>
            <p>
              Based on your answers and personal goals, you can reach your goal
            </p>
            <h2>Less wrinkles by February 11</h2>
            <p>And get ready to take lots of amazing photos</p>
            <div className={classes.chart}>
              <Chart />
            </div>
          </div>
          <div className={classes.benefits}>
            <div className={classes.gradFrame}>
              <div className={classes.benefitsContent}>
                <h3>FaceYoga Benefits</h3>
                <ul>
                  {BENEFITS.map((benefit) => (
                    <li>
                      <QuestionCheckbox key={benefit} checked /> {benefit}
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
          <div className={classes.choosePlan}>
            <h3>Choose your plan</h3>
            <div className={classes.plans}>
              {plans.map((plan, index) => (
                <PlanItem
                  key={plan.id}
                  onClick={() => setActivePlan(plan.id)}
                  checked={plan.id === activePlan}
                  duration={`${plan.billingPeriodDuration} ${plan.billingPeriodUnit}`}
                  priceNew={plan.priceAmount / 100}
                  priceOld={
                    plan.uiDisplayOptions.no_discount_price_amount / 100
                  }
                  perDay={plan.uiDisplayOptions.daily_price_amount}
                  popular={plan.uiDisplayOptions.title}
                  currency={plan.currency}
                  discountPercent={plan.uiDisplayOptions.discount_percents}
                />
              ))}
            </div>
            <div className={classes.control}>
              <Button id="purchase-continue-button" onClick={handleSubmitPlan}>
                Continue
              </Button>
              <p>
                By clicking "Continue", you agree to the Terms of Use, Privacy
                Policy, Billing Terms and Money-back Policy
              </p>
            </div>
          </div>
          <div className={classes.manyUsers}>
            <div className={classes.manyUsersBorder}>
              <img src={users} alt="users" height="22px" />
              <p>
                Many users around the world recommend our app! Choice + 100K
                users in 2023.
              </p>
            </div>
          </div>
          <div className={classes.moneyback}>
            <h4>100% money back guarantee</h4>
            <div className={classes.paragraph}>
              <p>
                We are confident in the quality of our product - tens thousands
                of users around the world who like it. Come on improve its
                quality together with us. If you are using FaceYoga for 3 days
                and you still don't like it, contact us - we guarantee 100%
                refund within 30 days after purchase.
              </p>
              <div className={classes.garant}>
                <Garant />
              </div>
            </div>
          </div>
          <div className={classes.footer}>
            <div className={classes.footerItem}>
              <h4>Your information is safe</h4>
              <p>
                We won't sell or rent your personal contact information for any
                marketing ourposes whatsoever.
              </p>
            </div>
            <div className={classes.footerItem}>
              <h4>Secure checkout</h4>
              <p>
                All information is encrypted and transmitted without risk using
                a Secure Sockets Layer protocol.
              </p>
            </div>
            <div className={classes.footerItem}>
              <h4>Need help?</h4>
              <p>
                Send us an email:{" "}
                <a href="mailto:help@face.yoga">help@face.yoga</a>
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
