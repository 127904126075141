import Modal from "../../common/Modal";
import classes from "./styles.module.scss";
import { NextButton } from "../../common/NextButton";
import { useNavigate } from "react-router";

export const ResultComponent = ({ icon, text, textButton, link }) => {
  const navigate = useNavigate();
  return (
    <Modal
      toogleModal={() => {
        navigate(link);
      }}
    >
      <div className={classes.result_component__body}>
        <span>{icon}</span>
        <p className={classes.paymentTitle}>{text}</p>
        <button
          className={classes.result_component__button}
          onClick={() => {
            navigate(link);
          }}
        >
          {textButton}
        </button>
      </div>
    </Modal>
  );
};
