import { TickSticker } from "./components/tickSticker";
import classes from "./styles.module.scss";
import { Header } from "../configurableQuestions/header";
import { useNavigate } from "react-router";

export const ThankYouPage = () => {
  const navigate = useNavigate();
  const today = new Date()
    .toLocaleString("en-GB", {
      month: "short",
      year: "numeric",
      day: "numeric",
    })
    .toUpperCase();
  return (
    <div className={classes.ty}>
      <header className={classes.header}>
        <Header initial />
      </header>
      <div className={classes.container}>
        <div className={classes.content}>
          <TickSticker />
          <h3>
            Your <span>FaceYoga </span>
            membership <br /> is active!
          </h3>

          <div className={classes.joining}>
            <p>DATE OF JOINING</p>
            <h5>{today}</h5>
          </div>
        </div>
        <div className={classes.controls}>
          <button
            onClick={() => navigate("/auth")}
            className={classes.navToProfile}
          >
            <span className={classes.text}>Go to My Account</span>{" "}
            <span className={classes.arrow}>&rarr;</span>
          </button>
        </div>
      </div>
    </div>
  );
};
