import { axiosInstance } from "../index";

const supportRequest = async (email, text) => {
    return axiosInstance
        .post("support-requests", {
            email,
            text,
        })
        .then((response) => {
            return response;
        });
};

const supportService = {
    supportRequest,
};

export default supportService;