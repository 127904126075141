export const Rate = () => {
  return (
    <svg
      width="89"
      height="18"
      viewBox="0 0 89 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_1308_3343)">
        <path
          d="M7.57341 4.39058C8.1612 2.58156 8.45509 1.67705 9 1.67705C9.54491 1.67705 9.8388 2.58156 10.4266 4.39058L10.6839 5.18237C10.8463 5.68237 10.9275 5.93237 11.1247 6.07561C11.3218 6.21885 11.5847 6.21885 12.1104 6.21885H12.943C14.8451 6.21885 15.7962 6.21885 15.9645 6.73708C16.1329 7.25532 15.3635 7.81434 13.8247 8.93237L13.1511 9.42173C12.7258 9.73075 12.5131 9.88525 12.4378 10.117C12.3625 10.3488 12.4438 10.5988 12.6062 11.0988L12.8635 11.8906C13.4513 13.6996 13.7452 14.6041 13.3043 14.9244C12.8635 15.2447 12.0941 14.6857 10.5552 13.5676L9.88168 13.0783C9.45635 12.7693 9.24369 12.6147 9 12.6147C8.75631 12.6147 8.54365 12.7693 8.11832 13.0783L7.44478 13.5676C5.90594 14.6857 5.13652 15.2447 4.69568 14.9244C4.25484 14.6041 4.54873 13.6996 5.13652 11.8906L5.39379 11.0988C5.55625 10.5988 5.63748 10.3488 5.56217 10.117C5.48687 9.88525 5.27421 9.73075 4.84888 9.42173L4.17534 8.93237C2.6365 7.81434 1.86708 7.25532 2.03546 6.73708C2.20385 6.21885 3.1549 6.21885 5.05702 6.21885H5.88956C6.41529 6.21885 6.67816 6.21885 6.87531 6.07561C7.07245 5.93237 7.15369 5.68237 7.31614 5.18237L7.57341 4.39058Z"
          fill="#F9DD77"
        />
        <path
          d="M25.3234 4.39058C25.9112 2.58156 26.2051 1.67705 26.75 1.67705C27.2949 1.67705 27.5888 2.58156 28.1766 4.39058L28.4339 5.18237C28.5963 5.68237 28.6775 5.93237 28.8747 6.07561C29.0718 6.21885 29.3347 6.21885 29.8604 6.21885H30.693C32.5951 6.21885 33.5462 6.21885 33.7145 6.73708C33.8829 7.25532 33.1135 7.81434 31.5747 8.93237L30.9011 9.42173C30.4758 9.73075 30.2631 9.88525 30.1878 10.117C30.1125 10.3488 30.1938 10.5988 30.3562 11.0988L30.6135 11.8906C31.2013 13.6996 31.4952 14.6041 31.0543 14.9244C30.6135 15.2447 29.8441 14.6857 28.3052 13.5676L27.6317 13.0783C27.2064 12.7693 26.9937 12.6147 26.75 12.6147C26.5063 12.6147 26.2936 12.7693 25.8683 13.0783L25.1948 13.5676C23.6559 14.6857 22.8865 15.2447 22.4457 14.9244C22.0048 14.6041 22.2987 13.6996 22.8865 11.8906L23.1438 11.0988C23.3062 10.5988 23.3875 10.3488 23.3122 10.117C23.2369 9.88525 23.0242 9.73075 22.5989 9.42173L21.9253 8.93237C20.3865 7.81434 19.6171 7.25532 19.7855 6.73708C19.9538 6.21885 20.9049 6.21885 22.807 6.21885H23.6396C24.1653 6.21885 24.4282 6.21885 24.6253 6.07561C24.8225 5.93237 24.9037 5.68237 25.0661 5.18237L25.3234 4.39058Z"
          fill="#F9DD77"
        />
        <path
          d="M43.0734 4.39058C43.6612 2.58156 43.9551 1.67705 44.5 1.67705C45.0449 1.67705 45.3388 2.58156 45.9266 4.39058L46.1839 5.18237C46.3463 5.68237 46.4275 5.93237 46.6247 6.07561C46.8218 6.21885 47.0847 6.21885 47.6104 6.21885H48.443C50.3451 6.21885 51.2962 6.21885 51.4645 6.73708C51.6329 7.25532 50.8635 7.81434 49.3247 8.93237L48.6511 9.42173C48.2258 9.73075 48.0131 9.88525 47.9378 10.117C47.8625 10.3488 47.9438 10.5988 48.1062 11.0988L48.3635 11.8906C48.9513 13.6996 49.2452 14.6041 48.8043 14.9244C48.3635 15.2447 47.5941 14.6857 46.0552 13.5676L45.3817 13.0783C44.9564 12.7693 44.7437 12.6147 44.5 12.6147C44.2563 12.6147 44.0436 12.7693 43.6183 13.0783L42.9448 13.5676C41.4059 14.6857 40.6365 15.2447 40.1957 14.9244C39.7548 14.6041 40.0487 13.6996 40.6365 11.8906L40.8938 11.0988C41.0562 10.5988 41.1375 10.3488 41.0622 10.117C40.9869 9.88525 40.7742 9.73075 40.3489 9.42173L39.6753 8.93237C38.1365 7.81434 37.3671 7.25532 37.5355 6.73708C37.7038 6.21885 38.6549 6.21885 40.557 6.21885H41.3896C41.9153 6.21885 42.1782 6.21885 42.3753 6.07561C42.5725 5.93237 42.6537 5.68237 42.8161 5.18237L43.0734 4.39058Z"
          fill="#F9DD77"
        />
        <path
          d="M60.8234 4.39058C61.4112 2.58156 61.7051 1.67705 62.25 1.67705C62.7949 1.67705 63.0888 2.58156 63.6766 4.39058L63.9339 5.18237C64.0963 5.68237 64.1775 5.93237 64.3747 6.07561C64.5718 6.21885 64.8347 6.21885 65.3604 6.21885H66.193C68.0951 6.21885 69.0462 6.21885 69.2145 6.73708C69.3829 7.25532 68.6135 7.81434 67.0747 8.93237L66.4011 9.42173C65.9758 9.73075 65.7631 9.88525 65.6878 10.117C65.6125 10.3488 65.6938 10.5988 65.8562 11.0988L66.1135 11.8906C66.7013 13.6996 66.9952 14.6041 66.5543 14.9244C66.1135 15.2447 65.3441 14.6857 63.8052 13.5676L63.1317 13.0783C62.7064 12.7693 62.4937 12.6147 62.25 12.6147C62.0063 12.6147 61.7936 12.7693 61.3683 13.0783L60.6948 13.5676C59.1559 14.6857 58.3865 15.2447 57.9457 14.9244C57.5048 14.6041 57.7987 13.6996 58.3865 11.8906L58.6438 11.0988C58.8062 10.5988 58.8875 10.3488 58.8122 10.117C58.7369 9.88525 58.5242 9.73075 58.0989 9.42173L57.4253 8.93237C55.8865 7.81434 55.1171 7.25532 55.2855 6.73708C55.4538 6.21885 56.4049 6.21885 58.307 6.21885H59.1396C59.6653 6.21885 59.9282 6.21885 60.1253 6.07561C60.3225 5.93237 60.4037 5.68237 60.5661 5.18237L60.8234 4.39058Z"
          fill="#F9DD77"
        />
        <path
          d="M78.5734 4.39058C79.1612 2.58156 79.4551 1.67705 80 1.67705C80.5449 1.67705 80.8388 2.58156 81.4266 4.39058L81.6839 5.18237C81.8463 5.68237 81.9275 5.93237 82.1247 6.07561C82.3218 6.21885 82.5847 6.21885 83.1104 6.21885H83.943C85.8451 6.21885 86.7962 6.21885 86.9645 6.73708C87.1329 7.25532 86.3635 7.81434 84.8247 8.93237L84.1511 9.42173C83.7258 9.73075 83.5131 9.88525 83.4378 10.117C83.3625 10.3488 83.4438 10.5988 83.6062 11.0988L83.8635 11.8906C84.4513 13.6996 84.7452 14.6041 84.3043 14.9244C83.8635 15.2447 83.0941 14.6857 81.5552 13.5676L80.8817 13.0783C80.4564 12.7693 80.2437 12.6147 80 12.6147C79.7563 12.6147 79.5436 12.7693 79.1183 13.0783L78.4448 13.5676C76.9059 14.6857 76.1365 15.2447 75.6957 14.9244C75.2548 14.6041 75.5487 13.6996 76.1365 11.8906L76.3938 11.0988C76.5562 10.5988 76.6375 10.3488 76.5622 10.117C76.4869 9.88525 76.2742 9.73075 75.8489 9.42173L75.1753 8.93237C73.6365 7.81434 72.8671 7.25532 73.0355 6.73708C73.2038 6.21885 74.1549 6.21885 76.057 6.21885H76.8896C77.4153 6.21885 77.6782 6.21885 77.8753 6.07561C78.0725 5.93237 78.1537 5.68237 78.3161 5.18237L78.5734 4.39058Z"
          fill="#F9DD77"
        />
      </g>
      <defs>
        <clipPath id="clip0_1308_3343">
          <rect width="89" height="18" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
