import { Button } from "../../../shared";
import { Plan } from "./Plan";
import classes from "./styles.module.scss";

export const MakePlanBetterBreakdown = ({ onNextStep }) => {
  return (
    <div id="breakdown" className={classes.breakdown}>
      <h3>Let's make your Personal Plan better</h3>
      <div className={classes.content}>
        <div className={classes.chart}>
          <Plan />
        </div>
        <div className={classes.focus}>
          <h5>WE FOCUS ON YOUR RESPONSES</h5>
          <p>
            All <strong>FACE YOGA</strong> Programs are designed by
            professionals, and tailored to your personalities
          </p>
        </div>
        <div className={classes.control}>
          <Button onClick={onNextStep}>Continue</Button>
        </div>
      </div>
    </div>
  );
};
