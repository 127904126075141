import {
  ButtonWithAnswerEmoji,
  ButtonsGroup,
  QuestionsContainer,
} from "../../../shared";

const ANSWERS = [
  {
    emoji: "🥦",
    title: "Yes",
  },
  {
    emoji: "🍖",
    title: "No",
  },
];

export const Vegetables = ({ onNextStep }) => {
  return (
    <QuestionsContainer title="Do you usually include vegetables in your meals?">
      <ButtonsGroup>
        {ANSWERS.map(({ emoji, title }) => (
          <ButtonWithAnswerEmoji emoji={emoji} onClick={onNextStep}>
            {title}
          </ButtonWithAnswerEmoji>
        ))}
      </ButtonsGroup>
    </QuestionsContainer>
  );
};
