import { useState } from "react";
import classes from "./../../styles.module.scss";
import SupportModal from "../supportModal/SupportModal";

const Issue = () => {
  const [modalOpen, setModalOpen] = useState(false);

  const toogleModal = () => setModalOpen((bool) => !bool);

  return (
    <div className={classes.issueContainer}>
      <p className={classes.issueTitle}>Any issues?</p>
      <button className={classes.issueButton} onClick={toogleModal}>
        Contact us
      </button>
      {modalOpen && <SupportModal toogleModal={toogleModal} />}
    </div>
  );
};

export default Issue;
