import classes from "./styles.module.scss";

export const ButtonWithAnswerEmoji = (props) => {
  return (
    <button {...props} className={classes.btnAnswer}>
      <div className={classes.emoji}>
        <p>{props.emoji}</p>
      </div>
      {props.children}
    </button>
  );
};
