import { ButtonsGroup } from "../../../shared/components/buttonsGroup";
import { QuestionsContainer, ButtonWithAnswer } from "../../../shared";

const AGES = ["20s", "30s", "40s", "50s", "60+"];

export const WhatIsYourAge = ({ onNextStep }) => {
  return (
    <QuestionsContainer title="What is your age?">
      <ButtonsGroup>
        {AGES.map((age) => (
          <ButtonWithAnswer onClick={onNextStep} key={age}>
            {age}
          </ButtonWithAnswer>
        ))}
      </ButtonsGroup>
    </QuestionsContainer>
  );
};
