import classes from "./styles.module.scss";
import logo from "../../../assets/img/png/logo.png";
import { BackIcon } from "./BackIcon";
import { useNavigate } from "react-router";

export const Header = ({ initial, onSkip }) => {
  const navigate = useNavigate();

  return (
    <div
      className={
        initial
          ? `${classes.content} ${classes.initial}`
          : `${classes.content} `
      }
    >
      {!initial && (
        <button onClick={() => navigate(-1)}>
          <BackIcon />
        </button>
      )}
      <div className={classes.logo}>
        <img src={logo} alt="logo" />
        <h2 className={classes.title}>FACE YOGA</h2>
      </div>
      {!initial && (
        <button onClick={() => onSkip?.()} className={classes.skip}>
          Skip
        </button>
      )}
    </div>
  );
};
