import { PlanCheckbox } from "../svg";
import classes from "./styles.module.scss";

const CurrencyMap = {
  USD: "$",
};

export const PlanItem = ({
  duration,
  priceOld,
  priceNew,
  perDay,
  popular,
  checked,
  onClick,
  currency,
  discountPercent,
}) => {
  return (
    <div
      className={
        checked ? `${classes.plan} ${classes.checked}` : `${classes.plan}`
      }
      onClick={onClick}
    >
      <div className={classes.container}>
        {popular && <div className={classes.mostPopular}>{popular}</div>}
        <div className={classes.planContent}>
          <div className={classes.price}>
            <PlanCheckbox checked={checked} />
            <div className={classes.priceText}>
              <h5>{duration}</h5>
              <p>
                {discountPercent !== 0 && (
                  <span>
                    {priceOld}
                    {CurrencyMap[currency]}
                  </span>
                )}{" "}
                {priceNew}
                {CurrencyMap[currency]}
              </p>
            </div>
          </div>
          <div className={classes.priceLabel}>
            <div className={classes.triangle} />
            <div className={classes.priceText}>
              <div className={classes.dollar}>{CurrencyMap[currency]}</div>
              <div className={classes.zero}>0</div>
              <div className={classes.pricePerDay}>
                <p>{perDay}</p>
                <p className={classes.perDay}>per day</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
