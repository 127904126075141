import React, { useState } from "react";
import classes from "./styles.module.scss";
import { Button } from "../../../shared/components/button";
import { CardWithCheckbox } from "../../../shared/components/cardWithCheckbox";
import { ButtonsGroup } from "../../../shared/components/buttonsGroup";
import { QuestionsContainer } from "../../../shared";
import { useChecks } from "../../../shared/hooks";

const cards = [
  "Get rid of wrinkles",
  "Improve skin tone",
  "Achieve youthful skin",
  "Get a perfect face shape",
];

export const SkinProblemsSelector = ({ onNextStep }) => {
  const { checkHandler, checkedCards, isChecked } = useChecks();
  return (
    <>
      <QuestionsContainer
        title="What's your main goal?"
        subtitle="Choose all that apply"
      >
        <ButtonsGroup>
          {cards.map((card) => (
            <CardWithCheckbox
              onClick={checkHandler(card)}
              key={card}
              title={card}
              checked={isChecked(card)}
            />
          ))}
        </ButtonsGroup>

        <div className={classes.control}>
          <Button disabled={!checkedCards.length} onClick={onNextStep}>
            Continue
          </Button>
        </div>
      </QuestionsContainer>
    </>
  );
};
