import { Images } from "../../../../../utils/images";
import { Button } from "../../../shared";
import { Rate } from "./Rate";
import classes from "./styles.module.scss";

export const ResultsBreakdown = ({ onNextStep }) => {
  const { before_after } = Images;
  return (
    <div id="breakdown" className={classes.breakdown}>
      <h3>
        Look at the results you can achieve with <span>Face yoga</span>
      </h3>
      <div className={classes.results}>
        <div className={classes.img}>
          <img src={before_after} alt="before" />
        </div>
        <div className={classes.content}>
          <Rate />
          <h5>😍 It's not real, but it works!</h5>
          <p className={classes.paragraph}>
            I never imagined looking youthful again. Observe my face; it's
            neater, smoother, and without wrinkles. My double chin also
            vanished!
          </p>
          <div className={classes.sign}>
            <p>Laura Williams</p>
            <p>July 19th, 2023</p>
          </div>
        </div>
      </div>
      <div className={classes.control}>
        <Button onClick={onNextStep}>Continue</Button>
      </div>
    </div>
  );
};
