import { Suspense } from "react";
import classes from "./styles.module.scss";
import { Image } from "../image";

export const QuestionsContainer = ({ title, subtitle, img, children }) => {
  return (
    <div className={classes.container}>
      <div className={classes.title}>
        {img && <img className={classes.img} src={img} alt="img" />}
        <h3>{title}</h3>
        {subtitle && <p>{subtitle}</p>}
      </div>
      <div className={classes.children}>{children}</div>
    </div>
  );
};
