import classes from "./styles.module.scss";

export const NextButton = ({ title, onClick, disabled }) => {

  return (
    <button
      className={classes.next_button}
      onClick={(event) => {
        onClick();
      }}
      disabled={!disabled}
    >
      {title}
    </button>
  );
};
