import { Images } from "../../../../../utils/images";
import {
  ButtonWithAnswer,
  ButtonsGroup,
  QuestionsContainer,
} from "../../../shared";

const ANSWERS = ["Yes", "No"];

export const ImproveJawline = ({ onNextStep }) => {
  const { improve } = Images;
  return (
    <QuestionsContainer title='"I want to improve my jaweline"' img={improve}>
      <ButtonsGroup>
        {ANSWERS.map((answer) => (
          <ButtonWithAnswer key={answer} onClick={onNextStep}>{answer}</ButtonWithAnswer>
        ))}
      </ButtonsGroup>
    </QuestionsContainer>
  );
};
