import {
  ButtonWithAnswerEmoji,
  ButtonsGroup,
  QuestionsContainer,
} from "../../../shared";

const ANSWERS = [
  {
    emoji: "🥱",
    title: "Less than 7 hours",
  },
  {
    emoji: "😴",
    title: "7-8 hours",
  },
  {
    emoji: "💤",
    title: "8-9 hours",
  },
  {
    emoji: "☀️",
    title: "More than 9 hours",
  },
];

export const Sleep = ({ onNextStep }) => {
  return (
    <QuestionsContainer title="How much do you sleep in average?">
      <ButtonsGroup>
        {ANSWERS.map(({ emoji, title }) => (
          <ButtonWithAnswerEmoji key={title} emoji={emoji} onClick={onNextStep}>
            {title}
          </ButtonWithAnswerEmoji>
        ))}
      </ButtonsGroup>
    </QuestionsContainer>
  );
};
