import { useState, useEffect, useCallback } from "react";
import close from "./../../../../assets/close.svg";
import classes from "./../../styles.module.scss";

const CountTimer = ({ minutes = 0, seconds = 0, executeScroll }) => {
  const [over, setOver] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);

  const [[m, s], setTime] = useState([minutes, seconds]);

  const tick = useCallback(() => {
    if (over) return;

    if (m === 0 && s === 0) {
      setOver(true);
    } else if (m === 0 && s === 0) {
      setTime([59, 59]);
    } else if (s === 0) {
      setTime([m - 1, 59]);
    } else {
      setTime([m, s - 1]);
    }
  }, [m, over, s]);

  useEffect(() => {
    const timerID = setInterval(() => tick(), 1000);
    if (m === 0 && s === 0) {
      setModalOpen(true);
    }
    return () => clearInterval(timerID);
  }, [m, s, tick]);

  return (
    <>
      <div className={classes.specialOfferTimerWrapper}>
        <p className={classes.specialOfferText}>
          {over
            ? "Special offer is still avaliable to you!"
            : "Special offer available"}
        </p>
        <p className={over ? classes.hideTimer : classes.timer}>{`${m
          .toString()
          .padStart(2, "0")}:${s.toString().padStart(2, "0")}`}</p>
      </div>
      {modalOpen && (
        <div className={classes.popUpContainer}>
          <div className={classes.popUpWrapper}>
            <div
              className={classes.closePopUp}
              onClick={() => setModalOpen(false)}
            >
              <img src={close} alt="close" />
            </div>
            <p className={classes.popUpTitle}>Discount time is finished</p>
            <p className={classes.popUpSubtitle}>
              But don’t you worry, it’s still valid just for you to enjoy
            </p>
            <button
              className={classes.popUpButton}
              onClick={() => {
                setModalOpen(false);
                executeScroll();
              }}
            >
              Wow, thanks
            </button>
          </div>
        </div>
      )}
    </>
  );
};

export default CountTimer;
