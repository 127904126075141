import React, { useState } from "react";

import { Header } from "../configurableQuestions/header";
import classes from "./styles.module.scss";
import { Button } from "../configurableQuestions/shared";
import axios from "axios";
import { useNavigate } from "react-router";
import { axiosInstance } from "../../api";
import authService from "../../api/services/authService";

export const GetPlan = () => {
  const [email, setEmail] = useState("");
  const [error, setError] = useState("");
  const navigate = useNavigate();

  const submitHandler = async (e) => {
    e.preventDefault();
    const submit = async () => {
      try {
        const res = await authService.emailOnlySignUp(email);
        localStorage.setItem("FY-AuthToken", res.data.token);
        navigate("/plan");
      } catch (error) {
        console.log(error);
        if (error.response.data.message) {
          setError(error.response.data.message);
        }
      }
    };
    await submit();
  };
  return (
    <div className={classes.questions}>
      <header className={classes.header}>
        <Header initial />
      </header>
      <form className={classes.content} onSubmit={submitHandler}>
        <div className={classes.email}>
          <div className={classes.title}>
            <h3>Your action plan is ready!</h3>
            <p>Enter your email to get started</p>
          </div>
          <div className={classes.input}>
            <input
              value={email}
              onChange={(e) => setEmail(e.currentTarget.value)}
              type="email"
              placeholder="Your Email"
            />
            {error && <p className={classes.error}>{error}</p>}
            <p>
              Your information is 100% secure. FaceYoga does not share your
              personal information with third parties.
            </p>
          </div>
        </div>
        <div className={classes.control}>
          <Button type="submit" disabled={email.length < 3}>
            Get My Plan
          </Button>
        </div>
      </form>
    </div>
  );
};
