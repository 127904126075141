import { combineReducers, configureStore } from "@reduxjs/toolkit";
import slices from "./slices";

import { reducer as subscription } from "./slices/subscriptionSlice";
import { reducer as modal } from "./slices/modalSlice";
import { reducer as steps } from "./slices/stepsSlice";


const reducer = combineReducers({
  slices,
  subscription,
  modal,
  steps,
});

const store = configureStore({
  reducer,
});

export default store;
