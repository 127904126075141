import { ResultComponent } from "./CommonResultComponent";

export const Resolve = () => {
  return (
    <ResultComponent
      icon={
        <svg
          width="70"
          height="70"
          viewBox="0 0 70 70"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M21 36.2903L29.4944 45L49 25"
            stroke="#436A61"
            stroke-width="4"
          />
          <rect
            x="0.5"
            y="0.5"
            width="69"
            height="69"
            rx="34.5"
            stroke="#436A61"
          />
        </svg>
      }
      text="Successful payment"
      textButton={"Thank you"}
      link='/auth'
    />
  );
};
