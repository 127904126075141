import welldone from "../assets/img/webp/welldone.webp";

export const Images = {
  areas: "img/webp/areas.webp",
  improve: "img/webp/improve.webp",
  younger: "img/webp/younger.webp",
  welldone: welldone,
  welcome: "img/webp/welcome_min.webp",
  before_after: "img/webp/be_aft_min.webp",
  users: "img/png/users.png",
};
