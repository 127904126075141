// Libs
import { useNavigate } from "react-router";

// Components
import { Title } from "../../common/Title";
import { NextButton } from "../../common/NextButton";

// Assets
import women from "../../assets/before_after__women.png";
import stars from "../../assets/stars.png";

import classes from "./styles.module.scss";

export const BeforeAfter = () => {
  const navigate = useNavigate();

  return (
    <div className={classes.page}>
      <Title>
        {
          "People all over the world get remarkable results with Face Yoga training"
        }
      </Title>
      <div className={classes.before_after_group}>
        <div className={classes.img_group}>
          <img src={women} alt="" />
        </div>
        <div className={classes.before_after_word_group}>
          <div>Before</div>
          <div>After</div>
        </div>
      </div>
      <div className={classes.review_wrapper}>
        <div className={classes.review_text}>
          <div>
            <p>Natalie B.</p>
            <img src={stars} alt="" />
          </div>
          <p>
            {" "}
            A few months ago everyone around me started noticing unbelievable
            changes in my face. Injections? Surgery?The answer is much more
            simple and affordable - Face Yoga I attained a sharper jawline,
            reduced my double-chin as well as wrinkles and started looking more
            youthful and bright overall. The training is easy and fun. Face Yoga
            is my recommendation to everyone!
          </p>
        </div>
      </div>
      <NextButton
        title={"Continue"}
        onClick={() => {
          navigate("/observe-process");
        }}
        disabled={true}
      />
    </div>
  );
};
